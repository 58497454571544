import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
  } from '@ngrx/store';
  import { environment } from '../../environments/environment';

  import * as flights from './flight-search/flight-search.reducer';
  import * as hotels from './hotel-search/hotel-search.reducer';
  import * as utilities from './app-utilities/app-utilities.reducer';
  import * as settings from './app-setting/app-setting.reducer';
  import * as user from './user/user.reducer';

  export interface State {
    flight    : flights.State,
    utilities : utilities.State,
    settings  : settings.State,
    user      : user.State
    hotel     : hotels.State
   }

   export const reducers: ActionReducerMap<State> = {
    flight    :flights.FlightReducer,
    utilities :utilities.UtilitiesReducer,
    settings  :settings.AppSettingReducer,
    user      :user.UserReducer,
    hotel     :hotels.HotelReducer
   }

   export const flightFeatureKey  = 'flight';
   export const utilityFeatureKey = 'utilities';
   export const settingFeatureKey = 'settings';
   export const userFeatureKey    = 'user';
   export const hotelFeatureKey    = 'hotel';

   export const selectFlightFeature    = createFeatureSelector<State, flights.State>(flightFeatureKey);
   export const selectUtilitiesFeature = createFeatureSelector<State, utilities.State>(utilityFeatureKey);
   export const selectSettingsFeature  = createFeatureSelector<State, settings.State>(settingFeatureKey);
   export const selectUserFeature      = createFeatureSelector<State, user.State>(userFeatureKey);
   export const selectHotelFeature     = createFeatureSelector<State, hotels.State>(hotelFeatureKey);

   //-------------> Hotels Search Selectors

   export const selectHotelLoader = createSelector(
    selectHotelFeature,
    (state : hotels.State) => state.loader
);

export const selectAllHotels = createSelector(
  selectHotelFeature,
    (state:hotels.State) => state.searchResult
  );

  export const selectFilteredHotels = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.filtredHotels
  );

  export const selectSelectedRooms = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.selectedRooms
  );

  export const selectTotalPrice = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.totalPrice
  );
  export const selectRequiredHotel = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.requiredHotel
  );

  export const selectHotelSearchCriteria = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.searchCriteria
  );

  export const selectCancellationPolicy = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.cancellationPolicy
  );

  export const selectHotelRooms = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.hotelRooms
  );

  export const selectHotelAmenties = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.hotelAmenties
  );

  export const selectRoomImages = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.roomImages
  );


    //-------------> Flights Search Selectors

    export const selectFlightLoader = createSelector(
        selectFlightFeature,
        (state : flights.State) => state.loader
    );
    

    export const selectFlightError = createSelector(
      selectFlightFeature,
      (state : flights.State) => state.error
  );

    export const selectAllFlights = createSelector(
        selectFlightFeature,
        (state:flights.State) => state.flights
      );

      export const selectAllFlightsLowestFare = createSelector(
        selectFlightFeature,
        (state:flights.State) => state.flightsLowestFare
      );

      export const selectFilteredFlights = createSelector(
        selectFlightFeature,
        (state:flights.State) => state.filterdFlights
      );

      export const selectFlightSearchCretaria = createSelector(
        selectFlightFeature,
        (state:flights.State) => state.searchCretaria
      );
      
      export const selectedFlight = createSelector(
        //selected flight
        selectFlightFeature,
        
        (state:flights.State) => state.selectedFlight
      );
      export const paymentview = createSelector(
        //selected flight
        selectFlightFeature,
        
        (state:flights.State) => state.link
      );
     
      export const fareRules = createSelector(
        //fare rule selector
        selectFlightFeature,
        (state:flights.State) => state.fareRules
      );
      
      export const offlineServices = createSelector(
        //offline service selector
        selectFlightFeature,
         (state:flights.State) => state.offlineServices)
      //-------------> App Utilities Selectors

      export const selectAllAirports = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.airports
      );

      export const selectAllCurrency = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.currency
      );

      export const selectAllPOS = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.pos
      );
      
      export const selectCountries = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.countryies
      );

      export const selectAllcities = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.cities
      );

      export const selectTopDestination = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State)=>state.FTcities
      );

      export const selectTripIdeas = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State)=>state.FTIdeas
      );
       

      //-------------> App Settings Selectors
      export const selectAppCurrency = createSelector(
        selectSettingsFeature,
        (state:settings.State) => state.currency
      );

      export const selectAppPOS = createSelector(
        selectSettingsFeature,
        (state:settings.State) => state.pos 
      );

      export const selectAppLang = createSelector(
        selectSettingsFeature,
        (state:settings.State) => state.currentLang
      );

      //-------------> user data Selectors.

      export const selectUserLoader = createSelector(
        selectUserFeature,
        (state:user.State) => state.loading
      );

      export const selectUserData = createSelector(
        selectUserFeature,
        (state:user.State) => state.user
      );


      export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
  