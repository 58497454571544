<div class="about-page">
    <!-- #header -->
    <app-header id="header"></app-header>
  
    <!-- #header -->
</div>

<section id="intro" class="clearfix">
    <div class="container d-flex h-100">
      <div class="row justify-content-center align-self-center">
        <div class="col-md-6 intro-info order-md-first order-last">
          <h2>An Easy Book Easy<br><span>Go!</span> Online Solution </h2>
          <div>
            <a href="" class="btn-get-started scrollto">Get Started</a>
          </div>
        </div>
  
        <div class="col-md-6 intro-img order-md-last order-first">
          <img src="../../../assets/img/intro-img.svg" alt="" class="img-fluid">
        </div>
      </div>
  
    </div>
  </section><!-- #intro -->
  <main id="main">
  
    <!--==========================
      About Us Section
    ============================-->
    <section id="about">
  
      <div class="container">
        <div class="row">
  
          <div class="col-lg-5 col-md-6">
            <div class="about-img">
              <img src="../../../assets/img/about-us-plane.jpg" alt="plane">
            </div>
          </div>
  
          <div class="col-lg-7 col-md-6">
            <div class="about-content">
              <h2>Company’s Profile</h2>
              <!-- <h3>It all started with a simple idea.</h3> -->
              <p>
                AL KHALEEJ TRAVELS<br>
                It was established in 1994 in the State of Kuwait and It is a member of Al-Namshan Group
                <br>
                It is chaired by
                <br>
                -Mr. Namshan Saud Al-Namshan
                <br>
                -Mr. Mohammad Saud Al-Namshan</p>
                <br>
              we have 17 branches in the State of Kuwait
              <br>
  -           Kuwait city       
  <br>
  -           Farwaniya
  <br>
  -           Khaitan
  <br>
  -           Jeleep AL Shuyoukh
  <br>
  -           Salmiya
  <br>
  -           Fahaheel
            </div>
          </div>
        </div>
      </div>
  
    </section><!-- #about -->
  
   <!--==========================
      Features Section
    ============================-->
    <section id="features">
        <div class="container">
    
          <div class="row feature-item">
            <div class="col-lg-6 wow fadeInUp">
              <img src="../../../assets/img/torest/beautiful-bright-hand-1252983-min.jpg" class="img-fluid" alt="">
            </div>
            <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
              <h3>Our Mission</h3>
              <p>
                Being widely recognized in its customer markets for providing best-in-class services and has continuously set the benchmark for the regional tourism industry. Our mission is to lead Kuwait’s premier corporate, business & travel operator is matched by our commitment to total client satisfaction.
              </p>
            </div>
          </div>
    
          <div class="row feature-item mt-5 pt-5">
            <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
              <img src="../../../assets/img/torest/bump-collaboration-colleagues-1068523-min.jpg" class="img-fluid" alt="">
            </div>
    
            <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
              <h3> Our Vision</h3>
              <p>
                An unwavering commitment to customer satisfaction that leads to a mutually beneficial and lifelong relationship. We place a premium on communication so our representatives can fully understand the client's needs. This understanding allows us to serve our clients by becoming partners in achieving their individual and business goals. Getting clients to their destination and back is only half the job; we seek to provide demonstrable value for customer satisfaction.
              </p>
            
            </div>
            
          </div>
          <div class="row feature-item mt-5 pt-5">
              <div class="col-lg-6 wow fadeInUp">
                <img src="../../../assets/img/torest/cardboard-communication-connecting-1246743-min.jpg" class="img-fluid" alt="">
              </div>
              <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
                  <h3>AL KHALEEJ TRAVELS </h3>
                  <p>
                    is a B2C online booking engine which enables clients to book online through over a million of travel routes and hotel bookings along with holiday packages all over the world. 
                  </p>
                  <p>
                   <span style='color:#422f63'
                   >
                  AL KHALEEJ TRAVELS
                  </span>  is connected to all of the airlines around the world. Also to many hotel service providers worldwide.
                  </p>
             
              </div>
            </div>
        </div>
      </section><!-- #about -->
    
    <!--==========================
      Services Section
    ============================-->
    <section id="services" class="section-bg">
      <div class="container">
  
        <div class="section-header">
          <h3>Services</h3>
          <p> More than 100 employees at your service</p>
        </div>
  
        <div class="row  align-items-center justify-content-center">
          
  
          <div class="col-md-2 wow bounceInUp " data-wow-duration="1.4s">
            <div class="box">
              <div class="icon" style="background: #422f63">                            <fa-icon class="icon" [icon]="faPlane" aria-hidden="true"  style="top:0% "></fa-icon>
              </div>
              <h4 class="title"><a href=""> Flight Reservation</a></h4>
          
            </div>
          </div>
          <div class="col-md-2 wow bounceInUp" data-wow-duration="1.4s">
            <div class="box">
              <div class="icon" style="background: #422f63">
                <fa-icon [icon]=" faHotel" style="color: #fff" ></fa-icon>

            </div>
              <h4 class="title"><a href="">Hotel Reservation</a></h4>
        
            </div>
          </div>
  
          <div class="col-md-2 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
            <div class="box">
              <div class="icon" style="background: #422f63">
                <fa-icon [icon]=" faCar" style="color: #fff" ></fa-icon>

                </div>
              <h4 class="title"><a href="">Car Rental</a></h4>
   
            </div>
          </div>
          <div class="col-md-2 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
            <div class="box">
              <div class="icon" style="background: #422f63">
                <fa-icon [icon]=" faMapMarkedAlt" style="color: #fff" ></fa-icon>
            </div>
              <h4 class="title"><a href="">Group Trips</a></h4>
  
            </div>
          </div>
  
          <div class="col-md-2 wow bounceInUp" data-wow-delay="0.1s" data-wow-duration="1.4s">
            <div class="box">
              <div class="icon" style="background: #422f63">
                <fa-icon [icon]=" faFutbol" style="color: #fff" ></fa-icon>
            </div>
              <h4 class="title"><a href="">  Exhibitions and Conferences</a></h4>
  
            </div>
          </div>
  
    
        </div>
  
      </div>
    </section><!-- #services -->
  
  
  
  </main>
<!-- <app-contact></app-contact> -->
<app-footer></app-footer>