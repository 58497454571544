import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronRight, faClipboardList, faEnvelope, faPhoneAlt, faSignOutAlt, faUser, faUserEdit, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { MyapiService } from '../../services/myapi.service';
export interface userinfo {
  Email:string,
  FullName:string,
  HasRegistered:boolean,
  PhoneNumber:string,
  UserID:string,
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  faUser=faUser;
  faSignOutAlt=faSignOutAlt;
  faEnvelope=faEnvelope;
  faPhoneAlt=faPhoneAlt;
  faUserEdit=faUserEdit;
  faChevronRight=faChevronRight;
  faClipboardList=faClipboardList;
  faUserFriends=faUserFriends;
  selector:string= 'upcoming';
  user :object;
  username:string='';
  email:string='';
  phone :string='';
  tok : string = '';
  id :string = '';
  loading:boolean = true;

  constructor(private myapi:MyapiService, private Router: Router ) {
    if(!sessionStorage.getItem('cls')){
      this.Router.navigate(['/']);
    }
   }

  ngOnInit() {
    this.tok = sessionStorage.getItem('cls');
    this.myapi.getuserinfo().subscribe((result:userinfo)=>{
      this.username = result.FullName;
      this.email = result.Email;
      this.phone = result.PhoneNumber;
      this.id = result.UserID;
      this.loading = false;
      console.log(this.username)
    })
    
  }
  select(choice:string){ 
    //  switch between component
    // this.Router.navigate([choice])
    

    console.log(choice)
     return this.selector = choice;
     
  
  }
  logout(){
   // logout 
   this.myapi.logoutapi().subscribe(
     (result)=>{
       console.log(result)
      sessionStorage.clear();
      this.Router.navigate(['/']);
      
     }
   )
  }

}
