import { convertToMin } from "../store/flight-search/flight-search.effects";
import { airItineraries, BaseSearchresult, flight, FlightDTO, searchCriteria, TransitArray } from "./flight-search-result";


export interface metaFlightRequest { 
    SearchId:string,
    POS: string,
    Currency: string,
    Language: string,
    Flights: metaFlght[],
    FlightType: string,
    PreferredAirline: string,
    SelectedFlightClass: string,
    AdultNum: number,
    ChildNum: number,
    InfantNum: number,
    SelectDirectFlightsOnly: boolean
}

export interface metaFlght {
    DepartingFrom: string,
    ArrivingTo: string,
    DepartingOnDate: string | Date
}
export interface metaFlightResponce extends BaseSearchresult{
    airItineraries:airItineraries[],
    airlines: any[],
    passengersDetails?: any[],
    CabinClasses:any[],
    SearchResultException?:any
} 
export interface metaSearchResult extends BaseSearchresult {
    airItineraries: signatureMapedItinerary[],
    airlines: any[];
} 
export interface signatureMapedItinerary extends airItineraries {
    options?: providers[];

}
export interface providers {
    price:number,
    providerLogo:string,
    otaName:string,
    referralLink:string,
    sequenceNum:number,
    flightSignature:string,
    currencyCode:string,
    isRefundable:boolean
}
type addAirlines = (airItineraries:airItineraries[]) => string [];
type createSignature = (fligts:flight[])=> string
type addSignature = (airItineraries:airItineraries[],fn:createSignature)=>airItineraries[];
type hashSignature = (airItineraries:airItineraries[])=>airItineraries[];
type mapSignature = (airItineraries:airItineraries[],fn1:createSignature,fn2:addSignature) =>airItineraries[][];
type addOverNight = (airItineraries:airItineraries)=> number;
type addStopsTime =(fligts:flight[])=> number;
type addExperiance =(price:number,totalstops:number,overnight:number)=> number;
type groupBysignature = (airItinerariesArray:airItineraries[],fn1:createSignature,fn2:addSignature,fn3:mapSignature,fn4:addOverNight,fn5:addStopsTime,fn6:addExperiance)=>signatureMapedItinerary[];
type mapFDTO =(signatureMapedItinerary:signatureMapedItinerary[])=>signatureMapedItinerary[];
type getmetaSearchResult= (metaFlightResponce:metaFlightResponce,fn1:createSignature,fn2:addSignature,fn3:mapSignature,f6:groupBysignature,fn7:addOverNight,fn8:addStopsTime,fn9:addExperiance)=>metaSearchResult;



export const addAirlinse = function (airItineraries:airItineraries[]):string []
{
    
return  [...new Set(airItineraries.map(v=>v.allJourney.flights[0].flightSegments[0].flightAirline.airlineCode))]
}
export const createSignatureString :createSignature = function(flight):string{
      let v :FlightDTO[] = []
      flight.forEach((f)=>{
        //   debugger
          v.push(...f.flightDTO)
      })
     let c = v.map(v=>[v.flightInfo.flightNumber,v.flightAirline.airlineCode,v.departureDate,v.arrivalDate])
     let result = c.reduce((accumulator, value) => accumulator.concat(value), []).sort().toString()

    return result
}
/**
 * calculate value to overnight
 * @param airItineraries 
 * @returns 
 */
export const overNight :addOverNight =function(airItineraries:airItineraries):number{
let arrivalDate:Date = new Date(airItineraries.arrivalDate);
let departualDate:Date = new Date(airItineraries.deptDate);
return arrivalDate.getDay() === departualDate.getDay()?0:arrivalDate.getHours()
}
/**
 * calculate the total stop time in hours
 * @param flights 
 * @returns 
 */
export const addStopTime:addStopsTime = function(flights:flight[]) :number{
        let TtransitTime :number = 0
        flights.forEach(flight => {
           let transitTime:number = flight.flightDTO.map(f=>f.transitTime.split(':').map(t=>parseInt(t))).reduce((ac,v)=>{
                return [ac[0]+v[0],ac[1]+v[1],ac[2]+v[2]]
            }).reduce((ac,v,I)=>{
                return ac + Math.floor(v/60**I)
            })
            TtransitTime += transitTime
        });
 return TtransitTime;
}
/**
 * evaluate flight experiance based on total stop time ,price ,over night 
 * as the value increase the over all experiance decrease
 * @param airItineraries 
 * @returns 
 */
export const addExperiance:addExperiance =function(price:number,totalstops:number,overnight:number) :number {
return price+totalstops+overnight;
}

export const addSignature:addSignature = function(airItineraries:airItineraries[],createSignatureString):airItineraries[] {   
    // debugger 
    let out :airItineraries[] = airItineraries.map((v)=>{return {...v,flightSignature:createSignatureString(v.allJourney.flights),pKey:0,providerLogo:'',providerName:''}});
    return out
}

  
export const mapSignature:mapSignature = function(airItineraries:airItineraries[],createSignatureString,addSignature) :airItineraries[][]{
   const arr = addSignature(airItineraries,createSignatureString);
   let remain:airItineraries[] = [...arr];
   let result:airItineraries[][]=[]
   let i = 0;
//    debugger
while (remain.length >0 || !remain) { 
        result.push(remain.filter((v,i,a)=>v.flightSignature === a[0].flightSignature));
      remain = remain.filter((v,i,a)=>v.flightSignature  !== a[0].flightSignature);
    i = i +1
  }
    return result
}
export const groupBysignature:groupBysignature = function(arr:airItineraries[],createSignatureString,addSignature,mapSignature,overNight,addStopTime,addExperiance)
 {
    // let mappedSig:airItineraries[][] = mapSignature(arr,createSignatureString,addSignature);
    // debugger
    let Output =  arr.map(
        (v)=>{
            // debugger
           let out:airItineraries = {
               ...v,
               sequenceNum: v.sequenceNum,
               pKey:v.pKey,
               isRefundable: v.isRefundable,
               stopsTime:addStopTime(v.allJourney.flights),
               overNight:overNight(v),
               experiance:addExperiance(v.itinTotalFare.amount,addStopTime(v.allJourney.flights),overNight(v)),
                itinTotalFare: v.itinTotalFare,
               totalDuration: v.totalDuration,
                deptDate: v.deptDate,
                arrivalDate: v.arrivalDate,
               cabinClass: v.cabinClass,
               flightType: v.flightType,
               allJourney: v.allJourney,
             baggageInformation: v.baggageInformation,
             searchCriteria:v.searchCriteria
           
           }
            return out
        }
    )
     return Output

 }
export const mapFDTO:mapFDTO = function (arr:signatureMapedItinerary[]):signatureMapedItinerary[]
 {

  return arr.map(v=>{
      return {...v,allJourney:{ ...v.allJourney,flights:v.allJourney.flights.map((f)=>{return{...f,flightDTO:f.flightSegments}})}}
  }) 
 }
export const getmetaSearchResult:getmetaSearchResult = function(metR:metaFlightResponce,createSignatureString,addSignature,mapSignature,groupBysignature):metaSearchResult {
// console.log(metR,"THE META RESPONSE NOOW");
// metR = {...metR , airItineraries:editAirlineNames(metR.airItineraries)}
// debugger
 let airItineraries :airItineraries[] =metR.searchCriteria.flightType.toLowerCase() === 'roundtrip'?metR.airItineraries.filter(v=>v.allJourney.flights.length == 2) : metR.airItineraries;
 let OutPut:metaSearchResult ={
     airItineraries:groupBysignature(airItineraries,createSignatureString,addSignature,mapSignature,overNight,addStopTime,addExperiance).sort((a,b) => {return a.itinTotalFare.amount - b.itinTotalFare.amount}),
     airlines:metR.airlines,
     searchCriteria:metR.searchCriteria,
     status:metR.status
 }
 return setPositionAndWidth(OutPut)
 }

export function setPositionAndWidth(items:metaSearchResult):metaSearchResult {
    let oldR = items;
    for(var i = 0 ; i < oldR.airItineraries.length ; i++){
        for(var f = 0 ;f < oldR.airItineraries[i].allJourney.flights.length; f++){
            oldR.airItineraries[i].allJourney.flights[f] = 
            setTransitPosition(oldR.airItineraries[i].allJourney.flights[f]);            
        }
    }
    return oldR
}



export function setTransitPosition(flight:flight) : flight{
    let TimePercent = (110/flight.elapsedTime);
    let transitArray:TransitArray[] = []; 
    flight.flightDTO.forEach((segment ,d)=> {

        transitArray.push({
            segIndex : d , segType:'leg',
            segWidth:setWidth(TimePercent,flight.flightDTO[d],'leg'),
            segPoision :setWidth(TimePercent,flight.flightDTO[d],'leg'),
            transitTime:flight.flightDTO[d].transitTime});
        if(segment.isStopSegment && segment.transitTime.localeCompare('00:00:00') !=0 ){
                transitArray.push({
                    segIndex : d , segType:'transit',
                    segWidth:setWidth(TimePercent , flight.flightDTO[d],'transit'),
                    segPoision :setPosition(transitArray),
                    transitTime:flight.flightDTO[d].transitTime})
        }
        
    });
    return {...flight , transitArray:transitArray}

}

export function setWidth(percentage:number,segment : FlightDTO , type:string):number{
    let width : number = 0
    let transitHours = segment.transitTime.split(':');
    let hours : number = (Number(transitHours[0])*60);
    let minutes:number = Number(transitHours[1]);
    let transitTimeInMinutes : number = hours + minutes;
    // console.log("transit time : " , segment.transitTime , 'in minutes = ' , transitTimeInMinutes);debugger;
    if(type == 'transit'){
        width = ((percentage * transitTimeInMinutes)) 
    }
    else{
        width = ((percentage * segment.durationPerLeg))
    }
    return width
}

export function setPosition(transitArray:TransitArray[]):number{
    // const reducer = (previousValue , currentValue) => previousValue + currentValue;
    // let sum = transitArray.map(v=>{return v.segWidth}).reduce((a:number,b)=> {return a + b} , transitArray.length-1);
    let sum : number = 0 ;
    for(var i = 0 ; i < transitArray.length ; i++){
        sum = sum + transitArray[i].segWidth
    }
    return (sum);
}

