import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import * as appSetting from '../app-setting/app-setting.actions';


@Injectable()
export class AppSettingEffects {



  constructor(private actions$: Actions ,private translate:TranslateService,@Inject(DOCUMENT) private document: Document ) {}

 
  langChange$ = createEffect(()=>this.actions$.pipe(
    ofType(appSetting.updateAppLang),
    map((v)=>{
      console.log(v.Lang, this.translate.currentLang)
      this.translate.use(v.Lang);
      localStorage.setItem('lang',v.Lang);
      if(v.Lang ==='ar'){
        this.document.dir ='rtl';
        // let arStyle =this.document.createElement('link');
        // arStyle.setAttribute('href','../assets/styels/styels.ar.css');
        // arStyle.setAttribute('rel','stylesheet');
        // arStyle.setAttribute('id','arStyle')
        // this.document.getElementsByTagName('head')[0].appendChild(arStyle);
      }else {
        this.document.dir ='ltr';
        // this.document.getElementsByTagName('head')[0].removeChild(this.document.getElementById('arStyle'));
      }
      return appSetting.updateAppLangSuccess({Lang:v.Lang});
    })
  ))
   

}
