<div class="terms-page">
    <app-header class="header"></app-header>
</div>

<div class="wraber terms-content">
    <div class="container">
      <div class="row title">
        <h1>Terms of Use</h1>
      </div>
      <div class="row align-self-center  justify-content-center">
     
        <div class="col-xs-12 col-sm-8 col-md-10 align-self-center justify-content-center  ">
          <div class="boxNewGrid privcy-terms-content hotelTerms">
            <div class="accordianBlock">
              <h3 id="a1" >AIR/FLIGHT BOOKINGS</h3>
              <div class="accord-dis">
                <p>All flight, hotel and car rental prices are subject to availability and offers may be withdrawn or amended at any time without prior notification. ALkhaleej Group trading as AL KHALEEJ TRAVELS accepts no liability should any such offer be withdrawn or amended.</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="b1" >AIRLINE CODESHARES</h3>
              <div class="accord-dis">
                <p>Many airlines operate on a codeshare basis with their commercial partners. This means that a flight can be operated on one carrier's behalf by a partner airline and and quite possibly bear more than one flight number. This particularly happens within airline consortia eg One World, Sky Team, Star Alliance etc whereby carriers are enabled to offer flights on their partner's aircraft to destinations beyond their existing routes and to utilize their additional services for check in etc.</p>
              </div>
  
            </div>
            <div class="accordianBlock">
              <h3 id="c1" >WEBSITE</h3>
              <div class="accord-dis">
                <p>Whilst we have taken every due care and diligence with the production of our website and price list, there may however be occasions beyond our control where certain fares are withdrawn by the airline. We will not be held responsible if for any reason these fares are withdrawn. No compensation is payable in the event of withdrawal of any fares.
                  Air fares are not guaranteed until tickets are confirmed and purchased. All fares are subject to confirmation and change prior to ticket issue.</p>
              </div>
  
            </div>
            <div class="accordianBlock">
              <h3 id="d1" >E-TICKETS  </h3>
              <div class="accord-dis">
                <p>Some airlines offer only electronic confirmation of your reservation, or 'e-ticketing', on certain routes. We cannot be held responsible if your e-ticket does not arrive due to an incorrect email address or your junk email settings. You can ensure your e-tickets get to you by adding AL KHALEEJ TRAVELS to your 'safe list'. You must notify us immediately if you change your email address or contact telephone number after making a booking with AL KHALEEJ TRAVELS. In addition, please check that the name on your passport matches the name on your ticket and/or booking confirmation. It is your responsibility to advise us if you have not received your eticket confirmation. E-tickets on special fares or allocation seats are issued 2 weeks prior to departure.</p>
              </div>
  
            </div>
            <div class="accordianBlock">
              <h3 id="e1" #E1>FLYING TIMES   </h3>
              <div class="accord-dis">
                <p>In your itinerary from AL KHALEEJ TRAVELS, flight times are given for guidance only. All departure/arrival times on your flight ticket are provided by the airlines concerned and are estimates only. They may change due to air traffic control restrictions, weather conditions, operational/maintenance requirements and the requirement for passengers to check in on time.
                  We are not liable if there is any change to a departure/arrival time or airport/s previously given to you or shown on your ticket or for any other alteration to your flight by the relevant airline. Also, please note that where a sector of a flight itinerary is not utilized without contacting the carrier directly any remaining sectors may be subject to cancellation without further notification. Where this situation arises we are unable to accept responsibility for any costs incurred. It is for this reason that you are required to reconfirm your flights with the airline or us in accordance with the airline's & Email Flights applicable reconfirmation deadline (normally 72 hours prior to departure). For all air travel it is your responsibility to check the departure and arrival times and airport/s of your flights at the time of travel.
                  We are unable to make any special arrangements for you if you are delayed as these matters are at the sole discretion of the airline concerned. Please note that the times shown on your ticket or itinerary are the departure times of the flight. Check-in times, as advised by the carrier or in the airlines’ timetable are the latest times at which passengers can be accepted for travel, allowing the necessary time to complete all formalities. Flights cannot be held up for passengers arriving late and no responsibility can be accepted by us or the airline in such cases.</p>
              </div>
  
            </div>
            <div class="accordianBlock">
              <h3 id="f1" #F1>SEAT REQUESTS</h3>
              <div class="accord-dis">
                <p>We have no control over the airlines’ allocation of seats and so, if you have particular seat requests, you should check-in at the airport as early as possible. We cannot guarantee that these requests will be met..</p>
              </div>
  
            </div>
            <div class="accordianBlock">
              <h3 id="g1" #G1>DIRECT FLIGHTS
  
              </h3>
              <div class="accord-dis">
                <p>Please note that a flight described on your flight ticket as "direct" will not necessarily be non-stop. A flight that is described as direct is one where there is no need to change aircraft during the journey.</p>
              </div>
  
            </div>  
               <div class="accordianBlock">
              <h3 id="h1" #H1>PREGNANCY AND CHILDREN</h3>
              <div class="accord-dis">
                <p>Some airlines refuse to carry women who will be 28 weeks or more pregnant on the date of return travel. Please check with the airline concerned as their rules may vary and also consult your doctor. Infants must be 6 weeks old or more to travel by air and must either sit on an adult's lap or occupy an infant seat. Please contact the airline you are travelling with for details of appropriate seats. Generally children aged 2 years or more must occupy a seat. A child must be under 2 years of age on the date of their return flight to be entitled to the infant fare level which is usually 10% of the International Air Transport Association’s (or IATA's) published fare. Airlines regard those aged 0-23 months for the entire travel period as infants, and those aged 2-11 years for the entire travel period as children. Any other person is required to pay the adult fare.</p>
              </div>
  
            </div>  
               
            <div class="accordianBlock">
              <h3 id="i1" #I1>GOVERNMENT IMPOSED TAXES, FEES AND CHARGES</h3>
              <div class="accord-dis">
                <p>The price of your flight may include taxes, fees and charges which are imposed on air transportation by government authorities. They may represent a significant portion of the cost of air travel and are either included in the fare or shown separately on your ticket. You may also be required to pay taxes or fees or other charges not already collected, for example, it is not always possible to include all departure taxes on your ticket(s). In some cases departure taxes must be paid by you locally to the Government of the country you are departing from and are therefore non-refundable by us.</p>
              </div>
  
            </div>   
            <div class="accordianBlock">
              <h3 id="l1" #L1>TICKET ISSUING CHARGES</h3>
              <div class="accord-dis">
                <p>AL KHALEEJ TRAVELS has the right to charge the client service fees under the name Ticketing Issuing Charge which rounds in the average 10 to 50 USD per passenger and the issuing fees is subject to be changed according to the different sales plans due to seasonality.</p>
              </div>
  
            </div>    
             <div class="accordianBlock">
              <h3 id="m1" #M1>BAGGAGE (SCHEDULED AIRLINES)</h3>
              <div class="accord-dis">
                <p>In most cases you will be permitted a free checked baggage allowance and a free cabin baggage allowance, the limits of which may differ by airline, class and/or route. It is recommended that cabin baggage is kept to a minimum. Extra charges may apply for checked baggage in excess of the permitted allowance. Please ask the airline for more specific information as these rules vary from time to time. For safety reasons dangerous articles must not be packed in checked or cabin baggage. Restricted articles include, but are not limited to, compressed gases, corrosives, explosives, flammable liquids and solids, radioactive materials, oxidizing materials, poisons, infectious substances and briefcases with installed alarm devices. Some long-haul airlines gives you a choice of fares when you fly in economy cabin. This gives you greater flexibility and allows you to pay for only the benefits and services you need. Baggage allowance is shown on the summary page before booking or confirming a booking. It is important for you to check and book the correct product. There is always a option to add extra baggage for a charge.</p>
              </div>
  
            </div>   
              <div class="accordianBlock">
              <h3 id="n1" #N1>BAGGAGE (NO FRILL AIRLINES)</h3>
              <div class="accord-dis">
                <p>In certain cases you will be offered the option to book baggage at an extra cost, at the time of the online booking. ALkhaleej Group trading as AL KHALEEJ TRAVELS do not accept responsibility for any changes made by the airline before departure.</p>
              </div>
  
            </div>   
              <div class="accordianBlock">
              <h3 id="o1" #O1>CANCELLATIONS AND ALTERATIONS BY CLIENT</h3>
              <div class="accord-dis">
                <div>If you or any member of your party wishes to cancel or alter a booking, written notification must be given to ALkhaleej Group trading as AL KHALEEJ TRAVELS. The date of cancellation will be the date when we receive our signed cancellation form by the card holder.
  
                  <ul>
                    <li>
                        Partial airport taxes are only refundable. (Fuel Surcharge Non-Refundable)
                    </li>
                    <li>
                        Changes are not permitted except in certain circumstances, and extra charges will apply.
                      </li>
                      <li>
                          Carriers reserve the right to change the flight schedule at any time prior to departure.
                        </li>
                        <li>
                            Tickets are non-transferable, non-reroutable, non-refundable and name changes are not permitted.
                          </li>
                       
                  </ul>
                  
                      All changes and cancellations are subject to an administration fee of about $65 per passenger from Mesafer in addition to airline charges.
                    
                
               
                </div>
              </div>
  
            </div>  
               
            <div class="accordianBlock">
              <h3 id="p1" #P1> HOTEL BOOKING TERMS AND CONDITIONS </h3>
              <div class="accord-dis">
                <p>All hotel prices are subject to availability and offers may be withdrawn or amended at any time without prior notification. AL KHALEEJ TRAVELS accepts no liability should any such offer be withdrawn or amended.
  
                    The following hotel booking conditions form the basis of the contract between you and ALkhaleej Group trading as AL KHALEEJ TRAVELS. The contract shall be governed in all respects by English Law and shall be subject to the exclusive jurisdiction of the English Courts. These terms and conditions constitute the terms upon which ALkhaleej Group trading as AL KHALEEJ TRAVELS have arranged the provision of accommodation only for you. These terms refer to all bookings made by telephone, fax, e-mail or via the online booking system. Please check your documentation carefully as soon as you receive it, if there are any incorrect details, please ensure you notify us immediately, as it may not be possible to make changes at a later date.</p>
              </div>
  
            </div> 
            <div class="accordianBlock">
              <h3 id="q1" #Q1>OUR RESPONSIBILITY TO YOU FOR YOUR BOOKING
  
                </h3>
              <div class="accord-dis">
                  <p>As we are acting only as a booking agent we have no liability for any of the accommodation arrangements and in particular any liability for any illness, personal injury, death or loss of any kind, unless caused by our negligence. Any claim for damages for injury, illness or death arising from your stay in the accommodation, must be brought against the owner of the accommodation and will be under the jurisdiction of the law of the country in which the accommodation is based.</p>
              </div>
  
            </div>     
      
            <div class="accordianBlock">
              <h3 id="j1" #J1>FORCE MAJEURE
  
                </h3>
              <div class="accord-dis">
                <p>This means that we will not pay you compensation if we have to cancel or change your travel arrangements in any way because of unusual or unforeseeable circumstances beyond our control. These can include, for example, war, riot, industrial dispute, terrorist activity and its consequences, natural or nuclear disaster, fire, adverse weather condition.</p>
              </div>
  
            </div>
           <div class="accordianBlock">
              <h3 id="r1" #R1>WEBSITE</h3>
              <div class="accord-dis">
                <p>Whilst we have taken every due care and diligence with the production of our website and price list, there may however be occasions beyond our control where certain facilities / room types are withdrawn by the accommodation providers. We will not be held responsible if for any reason these facilities / room types are withdrawn. No compensation is payable in the event of withdrawal of any facilities / room types.</p>
              </div>
  
            </div> 
            
            <div class="accordianBlock">
              <h3 id="s1" #S1>ROOM TYPES</h3>
              <div class="accord-dis">
                <p>A "Double room" does not guarantee a double bed; a Double room means a room for 2 persons, furthermore, please note a room sold as "Twin Room" does not guarantee twin beds.<br>
                    A "Triple room" means a room for 3 persons.<br>
                    A "Quad room" means a room for 4 persons.<br>
                    If you require either (1) Double bed in the room, or (2) single beds in a room, please REQUEST them at the time of booking.</p>
              </div>
  
            </div> 
            
          <div class="accordianBlock">
              <h3 id="t1" #T1>PRICES
  
                </h3>
              <div class="accord-dis">
                <p>Prices are subject to change, however once a booking is made and payment received in full, whatever happens to the pound, the price of the accommodation will not be subject to any changes.</p>
              </div>
  
            </div>  
            
          <div class="accordianBlock">
              <h3 id="w1" #W1>MAP/DISTANCE DISCLAIMER</h3>
              <div class="accord-dis">
                <p>Please note that the information as to the distance from the city center and the location of the hotel on the maps provided are for indicative purposes only. If a customer requires the hotel to be within a certain distance from the city center or near a particular location, the customer should contact the hotel in question prior to booking.</p>
              </div>
  
            </div> 
          <div class="accordianBlock">
              <h3 id="x1" #X1>UNAVAILABILITY</h3>
              <div class="accord-dis">
                <p>Where we are unable to confirm the initial requested accommodation, we will call you to inform you of the details of alternative accommodation or lack of availability. At that time you will have the option to accept the alternative accommodation or get a refund of the total monies paid to us by you for your original booking.</p>
              </div>
  
            </div>  
            
          <div class="accordianBlock">
              <h3 id="y1" #Y1>STAR RATINGS
  
                </h3>
              <div class="accord-dis">
                <p>Star ratings are used to symbolize the overall quality, level of service, food standard and range of facilities available in any given hotel property. The criteria applied within each country will vary depending on the specific requirements established by the relevant issuing body and are provided to you for information purposes only. They are not a guarantee or warranty of any kind by AL KHALEEJ TRAVELS.</p>
              </div>
  
            </div>   
          <div class="accordianBlock">
              <h3 id="a2" #A2>HOTEL FACILITIES
  
                </h3>
              <div class="accord-dis">
                <p>Please read the description of the hotel for other hotel policies applicable to your stay. You must be at least 21 years of age to check in to Las Vegas hotels.
                    Please note that the hotels booked by us for you are not exclusive to AL KHALEEJ TRAVELS. We are not responsible for any limitation in facilities because of other hotel guests or their activities. AL KHALEEJ TRAVELS does not take responsibility for hotel content (including images, facility listings, etc.) displayed on our website. Hotels may change facilities and property features without prior notice to AL KHALEEJ TRAVELS and it is the customers’ responsibility to confirm facilities directly with the hotel at the time of travel.</p>
              </div>
  
            </div>    
            
            
          <div class="accordianBlock">
              <h3 id="b2" #B2>LOCAL TAXES NOT INCLUDED IN HOTEL COST
  
                </h3>
              <div class="accord-dis">
                <p>Please note that there may be taxes levied abroad but not paid at the point of purchase that are payable in relation to your hotel booking e.g. local taxes, sales tax etc. Any local taxes will be payable by you directly to the hotel at the time of check in / checkout.</p>
              </div>
  
            </div>   
            
            <div class="accordianBlock">
              <h3 id="c2" #C2>DESCRIPTIONS AND PHOTOGRAPHS
  
                </h3>
              <div class="accord-dis">
                <p>All pictures and descriptions are displayed in good faith. However in certain circumstances beyond our control accommodation descriptions may be affected, such as sea views, which may be obscured by greenery, such as trees. We try to maintain up to date information on any changes and therefore all details printed should be regarded for general information only. Accommodation categories and star ratings shown on the website are provided by the accommodation, however standards can vary between accommodations of the same class in different countries, it is therefore important that the accommodation details are read in full.</p>
              </div>
  
            </div> 
            
            
            <div class="accordianBlock">
              <h3 id="d2" #D2>ACCOMMODATION
  
                </h3>
              <div class="accord-dis">
                <p>All accommodation is reserved exclusively for use by the person named as the lead passenger plus the total number of passengers, whether adults or children, as shown on the Invoice / Accommodation Voucher. Unauthorized occupancy may result in the accommodation refusing occupation with immediate cancellation and loss of all monies paid. Most accommodation is sold on a twin/double room basis, however most properties have more twin rooms than doubles. If a double bed is requested please be aware that this may be two single beds pushed together. Additional beds for triple/quad rooms could either be a pull out sofa bed, foldable bed, rollaway beds, or bunk beds. Self-Catering properties usually do not offer a choice of bed type. Some properties impose a minimum number of nights per stay and bookings may incur a surcharge if less than the minimum period allowed.</p>
              </div>
  
            </div>   
          <div class="accordianBlock">
              <h3 id="e2" #E2>GROUP BOOKINGS
  
                </h3>
              <div class="accord-dis">
                <p>Parties of 10 persons or more are classed as a group booking and must not be split into multiple smaller bookings.
  
                  </p>
              </div>
  
            </div> 
            
        <div class="accordianBlock">
              <h3 id="f2" #F2>BOOKING AGES
  
                </h3>
              <div class="accord-dis">
                <p>It is a condition of booking that we do not accept any bookings / reservations from a person aged under 18 years old. If it comes to our attention that a booking has been made by a person under 18 years old, then we are entitled to cancel any booking that is in breach of these terms. In this instance no refunds will be given.</p>
              </div>
  
        </div>
         <div class="accordianBlock">
              <h3 id="g2" #G2>CHECK-IN / CHECK-OUT</h3>
              <div class="accord-dis">
                <p>Times are normally around 15.00 – 16.00 on the day of arrival and between 10.00 am and 12.00 hours on the day of departure depending upon property type</p>
              </div>
  
         </div>    
             <div class="accordianBlock">
              <h3 id="h2" #H2>DELAYS</h3>
              <div class="accord-dis">
                <p>On occasions there may be delays or missed departures, due to the failure of transportation or other circumstances. We cannot be held liable if the accommodation is not available on arrival unless the Property is notified directly using the telephone number provided on the voucher. Refunds will not be given for any no shows.</p>
              </div>
  
            </div>    
            
            
          <div class="accordianBlock">
              <h3 id="i2" #I2>BUILDING WORK
  
                </h3>
              <div class="accord-dis">
                <p>Due to continuous re-development throughout the world there is always going to be some building work going on somewhere. Provided that we are made aware by the Property that any such work is liable to interfere with the enjoyment of your holiday, then we will advise you accordingly. However please be aware that the accommodation has no liability to inform us of any building work in the surrounding area outside their property..</p>
              </div>
  
            </div>   
          <div class="accordianBlock">
              <h3 id="j2" #J2>CANCELLATIONS AND ALTERATIONS BY CLIENT
  
                </h3>
              <div class="accord-dis">
                <p>If you or any member of your party wishes to cancel or alter a booking, written notification must be given to ALkhaleej Group trading as AL KHALEEJ TRAVELS. The date of cancellation will be the date we receive the written notice. In the event of cancellation, the following cancellation charges will apply.
  
                    Your hotel is supplied by third party agencies. These third party agencies will have additional cancellation policies that may be applicable and in addition your chosen hotel will typically have its own cancellation policy. ALkhaleej Group trading as AL KHALEEJ TRAVELS will pass on any such policies or charges that the hotel or third party agency has imposed in relation to cancellation to the customer PLUS $65 ADMINISTRATION FEE. In the absence of the hotel having its own policy, the cancellation fee charged by ALkhaleej Group trading as AL KHALEEJ TRAVELS will be $65 per booking.</p>
              </div>
  
            </div> 
            
            <div class="accordianBlock">
              <h3 id="k2" #K2>ALTERATIONS MADE BY YOU PRIOR TO DEPARTURE</h3>
              <div class="accord-dis">
                <p>In the event of an alteration, the following administration charge will apply. $65 per booking for each change made after the booking is confirmed: this applies for changes made up to 28 days prior to commencement of travel. Changes made less than 28 days prior to travel may incur higher charges or be regarded as a cancellation.</p>
              </div>
  
            </div>    
             <div class="accordianBlock">
              <h3 id="l2" #L2>CONFIRMED BOOKINGS
  
                </h3>
              <div class="accord-dis">
                <p>In very rare cases an accommodation may not honor a confirmed booking, this is beyond our control and in the event that this happens, we will give a full refund. ALkhaleej Group trading as AL KHALEEJ TRAVELS, accepts no responsibility in respect of any costs, losses or damages that may be incurred in connection with any confirmed booking cancellations, since such cancellation is outside the control of ALkhaleej Group trading as AL KHALEEJ TRAVELS.</p>
              </div>
  
            </div>   
              <div class="accordianBlock">
              <h3 id="m2" #M2>ACCOMMODATION OVER BOOKED ON ARRIVAL</h3>
              <div class="accord-dis">
                <p>In the very unlikely event that on arrival the property cannot provide the accommodation booked, it is the responsibility of the property to find an alternative property of a similar standard. ALkhaleej Group trading as AL KHALEEJ TRAVELS have taken every precaution to ensure that all properties are professionally managed so that any such occurrences are extremely rare. ALkhaleej Group trading as AL KHALEEJ TRAVELS, accepts no responsibility in respect of any costs, losses or damages that may be incurred in connection with relocation of accommodation since such relocation is outside the control of ALkhaleej Group trading as AL KHALEEJ TRAVELS</p>
              </div>
  
            </div>   
              <div class="accordianBlock">
              <h3 id="n2" #N2>COMPLAINTS</h3>
              <div class="accord-dis">
                <p>Any complaints must be immediately brought to the attention of the relevant accommodation in resort, in order to try and resolve the problem there and then, in order to minimise any inconvenience, if you fail to follow this procedure we cannot be held responsible, as some Hotels / Properties will refuse to deal with any complaints that have not been notified in resort. The Hotel / Property must be given a reasonable opportunity to correct the situation from the outset. If the matter cannot be resolved in resort by following the aforementioned procedure and you are still dissatisfied, your complaint should be received by ourselves in writing within 7 days of your return and we will endeavor, on your behalf, to resolve the matter with the relevant property. We regard it as unreasonable if you fail to deal with any matter whilst in resort and therefore if you fail to follow this procedure we will not regard ourselves liable for your failure to deal with the matter in resort.</p>
              </div>
  
            </div> 
  
            <div class="accordianBlock">
                <h3 id="o2" #O2>SPECIAL REQUESTS</h3>
                <div class="accord-dis">
                  <p>We will always where possible pass on any requests to a Hotel / Property but NO GUARANTEE CAN BE GIVEN that a request will be honored, therefore any reservation made, will not be conditional upon the confirmation of a special request.</p>
                </div>
    
              </div>   
  
           <div class="accordianBlock">
                  <h3 id="p2" #P2>TRAVEL INSURANCE
  
                    </h3>
                  <div class="accord-dis">
                    <p>We strongly recommend that you do not travel without adequate Travel Insurance for your own protection and peace of mind, please be aware that some airlines may not allow you to travel without insurance. It is your responsibility to ensure that you purchase adequate travel insurance for your needs </p>
                  </div>
      
           </div>      
           <div class="accordianBlock">
                    <h3 id="q2" #Q2>GENERAL TERMS & CONDITIONS</h3>
                    <div class="accord-dis">
                      <p>All flight, hotel and car rental prices are subject to availability and offers may be withdrawn or amended at any time without prior notification. AL KHALEEJ TRAVELS accepts no liability should any such offer be withdrawn or amended</p>
                    </div>
        
           </div> 
                  
            <div class="accordianBlock">
                      <h3 id="r2" #R2>TERMS AND CONDITIONS OF BOOKINGS</h3>
                      <div class="accord-dis">
                        <p>Air fares are not guaranteed until tickets are confirmed and purchased. All fares are subject to confirmation and change prior to ticket issue.
  
                            The booking classes to which the airfares are quoted are indicative and cannot be guaranteed by ALkhaleej Group trading as AL KHALEEJ TRAVELS.
                            
                            ALkhaleej Group trading as AL KHALEEJ TRAVELS is the appointed Kuwait travel agent of ALkhaleej Group trading as AL KHALEEJ TRAVELS and other travel suppliers. The terms and conditions imposed by these suppliers should be considered as applied to your contract with ALkhaleej Group trading as AL KHALEEJ TRAVELS. Please contact the appropriate supplier for details of their terms and conditions. Purchase of an airline ticket does not guarantee you a seat on a specified aircraft. Airline overbooking policy often results in failure of the carrier to provide a seat on a booked flight for a passenger holding a confirmed ticket. Please contact individual carriers for full details of their overbooking and compensation policy. Tickets are sold to credit card holders only. Credit card information is validated prior to the transaction being finalized. Dispatch address for tickets must be the same as billing address for the card. Air tickets have value and should be treated like cash. Never destroy tickets which are not required or are unused. The carrying of appropriate travel documents, including valid passport and where appropriate the necessary visas, is the responsibility of the traveler. ALkhaleej Group trading as AL KHALEEJ TRAVELS expressly declines responsibility for any and all costs incurred in the case that a traveler or party are denied boarding to an aircraft, or denied entry to a country because the traveler or party is not in possession of the appropriate documentation. Travel insurance is highly recommended.</p>
                      </div>
          
             </div>
             <div class="accordianBlock">
              <h3 id="s2" #S2>AIRLINE CODESHARING
  
                </h3>
              <div class="accord-dis">
                <p>Flights and services may be operated by a partner carrier on a code share basis or by a substitute supplier at the operator or service supplier's discretion for technical, operational or commercial reasons at any time. Aircraft or transportation type can never be guaranteed and substitutions to equipment type may take place at the airline or supplier's discretion for technical, operational or commercial reasons.</p>
              </div>
  
            </div>
            <div class="accordianBlock">
                <h3 id="t2" #T2>EXCLUSION OF WARRANTY</h3>
                <div class="accord-dis">
                  <p>ALkhaleej Group trading as AL KHALEEJ TRAVELS and third party providers and distributors do not warrant the accuracy, completeness, merchantability or fitness for a particular purpose of any of the content or data found on this site.</p>
                </div>
    
              </div>
              <div class="accordianBlock">
                  <h3 id="w2" #W2>LIMITATION OF LIABILITY</h3>
                  <div class="accord-dis">
                    <p>In no event shall ALkhaleej Group trading as AL KHALEEJ TRAVELS or third party providers or distributors be liable for any injury, loss, claim, damage, or any special, exemplary, punitive, incidental or consequential damages of any kind (including, but not limited to lost profits or lost savings), whether based in contract, tort or otherwise, which (i) arises out of or is in any way connected with any use of this site or content found herein, (ii) any failure, change or delay (including, but not limited to the use of or inability to use any component of this site for reservations or ticketing), or (iii) the performance or non performance by ALkhaleej Group trading as AL KHALEEJ TRAVELS or third party providers or distributors, even if such party has been advised of the possibility of such damages, or for any other party. Some states do not allow the exclusion of implied warranties or the limitation or exclusion of liability for incidental and consequential damages, so the above exclusion may not apply to you. If, notwithstanding the foregoing, ALkhaleej Group trading as AL KHALEEJ TRAVELS, or any third party provider or distributor should be found liable for any loss or damage which arises out of or is in any way connected with any of the above described functions or uses of this site or its content, ALkhaleej Group trading as AL KHALEEJ TRAVELS and third party provider's and distributor's liability shall in no event exceed, in the aggregate, the subscription fee and service charge for accessing this site with respect to which liability is found. In its sole discretion, in addition to any other rights or remedies available to ALkhaleej Group trading as AL KHALEEJ TRAVELS and without any liability whatsoever, ALkhaleej Group trading as AL KHALEEJ TRAVELS at any time and without notice may terminate or restrict your access to any component of this site. The foregoing terms and conditions shall apply to both contract and negligence claims.
                      <br>
                        In respect of international travel our liability to you will be limited or excluded in the same manner provided by the relevant international conventions mentioned below. This means that our liability to you shall be limited to the actual engaged liability (if any) of the operating carrier or transport company which undertakes the relevant travel services.
                        Email Flights is recognized as an organizer for travel and holidays only and, as such, does not control or operate any airline, shipping company or rail company. When you travel your journey may be subject to certain international conventions such as, but not limited to, the Warsaw convention and Montreal convention (in respect of international carriage by air), the Athens convention (in respect of international carriage by sea) or COTIF (The convention Concerning International Carriage by Rail), as such conventions are amended or re-enacted from time to time (the "Conventions"). You agree that these Conventions will apply to you on that journey. Please click here to read these Conventions which are incorporated into these terms and conditions.
                        You agree that the operating carrier or transport company's own "Conditions of Carriage" will apply to you on that journey. When arranging this transportation for you, we rely on the terms and conditions and limits of liability contained within these international conventions and those "Conditions of Carriage". You acknowledge that all of the terms and conditions contained in those "Conditions of Carriage" form part of your contract with us as well as the transport company and that those "Conditions of Carriage" shall be deemed to be included by reference into your contract.</p>
                  </div>
      
                </div>        
                <div class="accordianBlock">
                    <h3 id="x2" #X2>AGE AND RESPONSIBILITY</h3>
                    <div class="accord-dis">
                      <p>You represent that you are of sufficient legal age to use this site and to create binding legal obligations for any liability you may incur as a result of the use of this site. You understand that you are financially responsible for all uses of this site by you and those using your login information.</p>
                    </div>
        
              </div>         
                    <div class="accordianBlock">
                      <h3 id="y2" #Y2>PRIVACY
  
                        </h3>
                      <div class="accord-dis">
                        <p>As a customer of ALkhaleej Group trading as AL KHALEEJ TRAVELS, you are in complete control of your travel planning needs. This includes controlling the use of information you provide ALkhaleej Group trading as AL KHALEEJ TRAVELS. When you reserve or purchase travel services through ALkhaleej Group trading as AL KHALEEJ TRAVELS, we provide information about you to the airline, car rental agency, hotel, ALkhaleej Group trading as AL KHALEEJ TRAVELS or other involved third party to ensure the successful fulfilment of your travel arrangements. We also use information you provide during registration or as part of the reservation process to customize the content of our site to meet your specific needs and to make product improvements to ALkhaleej Group trading as AL KHALEEJ TRAVELS. ALkhaleej Group trading as AL KHALEEJ TRAVELS will send you an e-mail message confirming each reservation you transact online. We do not sell individual customer names or other private profile information to third parties. As a member or contest entrant you will occasionally receive updates from us about fare sales in your area, special offers, new ALkhaleej Group trading as AL KHALEEJ TRAVELS services and noteworthy news. We hope you will find these updates interesting and informative. ALkhaleej Group trading as AL KHALEEJ TRAVELS reserves the right to limit membership to those who will accept e-mails. We respect and will continue to respect the privacy of our customers who use ALkhaleej Group trading as AL KHALEEJ TRAVELS.</p>
                      </div>
          
                    </div>    
               <div class="accordianBlock">
                        <h3 id="z2" #Z2>CARRYING PROOF OF PURCHASE</h3>
                        <div class="accord-dis">
                          <p>You should ensure that you travel with your booking confirmations, e-tickets and any other travel documentation (including your passport) at all times. We will not be liable for any supplier not providing you with the booked product or service if you do not produce such documentation.</p>
                        </div>
            
              </div>       
              <div class="accordianBlock">
                          <h3 id="a3" #A3>CANCELLING YOUR INSURANCE POLICY</h3>
                          <div class="accord-dis">
                            <p>All insurance premiums are payable in full at the point of sale and are sold incorporating a 14 day cooling off period after which time some policies may be non-refundable. The 14 day cooling off period only applies if you have not travelled or made any claim under the insurance policy. It is your responsibility to check the specific terms of the insurance policy at the time of purchase.</p>
                          </div>
              
             </div>
             <div class="accordianBlock">
                      <h3 id="b3" #b3>CHANGES AND CANCELLATIONS</h3>
                      <div class="accord-dis">
                        <p>Occasionally we may have to make changes to your booking. Most of these changes will be minor and we will advise you of the change as soon as we are able to before your journey commences. If there is a significant change, which is due to an event beyond our control, you will be offered a choice of an alternative comparable trip (if available) or a refund excluding the $65 admin fee per person. If the reason for the significant change to the booking is due to an event beyond our control (as described below) we will not be liable to offer you any compensation.<br>
                            Significant changes are normally regarded as:-
                            i) A change in your departure airport and or arrival airport;<br>
                            ii) A change of your onward or your inward flight date/time.<br>
                            iii) A change in your resort area overseas; or<br>
                            iv) A change of accommodation to a lower classification than that which you have booked<br>
                            In the unlikely event that your booking has to be cancelled, a full refund will be made of all monies previously paid to us to the person/s who originally paid for the booking excluding a $65 admin fee per passenger.
                            Should your flight be cancelled, your rights and remedies will be governed by the airline's conditions of carriage. As a result you may be entitled to:<br>
                              (a) carriage on another flight with the same airline without additional costs; <br>
                              (b) re-routing to your destination with another carrier without additional costs;<br>
                              (c) receiving a full refund excluding the $65 admin charge per passenger; or <br>
                              (d) some other right or remedy.</p>
                      </div>
          
              </div>       
              <div class="accordianBlock">
                              <h3 id="c3" #C3>EVENTS BEYOND OUR CONTROL</h3>
                              <div class="accord-dis">
                                <p>These include: unusual, unforeseeable or unavoidable events beyond our control, the consequences of which could not be avoided even if all due care has been exercised including but not limited to war, threat of war, riot, civil disobedience or strife, government action, terrorist activity, natural or industrial disaster, fire, adverse weather conditions, level of water in rivers or floods, closure of airports and may also include industrial dispute involving third parties, technical or maintenance problems or unforeseen operational decisions of air carriers such as changes of schedule or any failure in the information technology infrastructure (including but not limited to our website) or failure in internet connections. Except where otherwise expressly stated in these booking conditions we regret that we cannot accept liability or pay any compensation where the performance of our contractual obligations is prevented or affected by reason of such events beyond our control.
   
  
                                    All changes and cancellations are subject to an administration fee of $65 per passenger from Mesafer in addition to airline charges.</p>
                              </div>
                  
              </div> 
                            
               <div class="accordianBlock">
                                <h3 id="d3" #D3>NO SHOW
  
                                  </h3>
                                <div class="accord-dis">
                                  <p>If you have a booking for any of our products or services, including flights, car hire, accommodation and packages, but you do not show up to check-in, collect the car or otherwise do not avail yourself of such product or service, you will not be entitled to any refund from us.</p>
                                </div>
                    
                </div>     
                 <div class="accordianBlock">
                                  <h3 id="e3" #E3>YOUR BEHAVIOR
  
                                    </h3>
                                  <div class="accord-dis">
                                    <p>It is your responsibility to ensure that you and the members of your group do not behave in a way which is inappropriate or causes danger, distress, offence or damage to others or which risks damage to property belonging to others (including but not limited to drunkenness and air rage) whilst on your trip.
                                        If, in our reasonable opinion or that of our suppliers, your behavior is inappropriate and causes danger, distress, offence, or damage to others, or risks damage to property belonging to others, we and/or our suppliers (e.g. hotel managers, airline pilots) may take appropriate action in order to ensure the safety and comfort of our customers and their property and that of our suppliers, including terminating your trip, in which case our and our supplier's responsibility to you will cease immediately and you will not be eligible for any refunds, payments of compensation and/or any reimbursement of any cost or expenses you may incur as a result of such termination. Further, you will be liable to reimburse us for any expenses we incur necessarily as a result of such termination.</p>
                                  </div>
                      
                   </div>        
                 <div class="accordianBlock">
                                    <h3 id="f3" #F3>CHANGES TO TERMS AND CONDITIONS</h3>
                                    <div class="accord-dis">
                                      <p>We may amend these terms and conditions at any time without prior notice. If we do amend these terms and conditions, the amended terms will be effective when posted on this website and you are deemed to have accepted the relevant changes.</p>
                                    </div>
                        
        </div>  
        <div class="accordianBlock">
        <h3 id="g3" #G3>SPECIAL ASSISTANCE
  
        </h3>
        <div class="accord-dis">
        <p>If you are disabled or incapacitated or of limited mobility or require special assistance for any reason, we require that you notify us at the time of making your booking. If these requirements change before your trip you must inform us. We will not subsequently be liable to you for any losses you may incur in the event that we or our suppliers are unable to accommodate your requirements.</p>
        </div>
  
        </div>      
        <div class="accordianBlock">
        <h3 id="h3" #H3>WEATHER</h3>
        <div class="accord-dis">
        <p>We cannot be held responsible for any disruption to your trip due to bad or unusual weather conditions.</p>
        </div>
  
        </div>
            <div class="accordianBlock">
        <h3 id="i3" #I3>SPECIAL REQUESTS
  
        </h3>
        <div class="accord-dis">
        <p>Please advise us of any special requests and we will pass these on to the relevant supplier. However, we cannot guarantee your special request as we do not have any direct control over the manner in which the services are provided.</p>
        </div>
  
        </div>      
        <div class="accordianBlock">
        <h3 id="j3" #J3>TELEPHONE CALLS
  
          </h3>
        <div class="accord-dis">
          <p>We reserve the right to randomly record telephone calls to ensure that our customer service is constantly reviewed.
            </p>
        </div>
  
        </div>
  
        <div class="accordianBlock">
          <h3 id="K3" #K3>THIRD PARTY RIGHTS
            </h3>
          <div class="accord-dis">
            <p>A person who is not a party to these terms and conditions shall have no rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of these booking terms and conditions. This paragraph does not affect any right or remedy of any person which exists or is available otherwise than pursuant to that Act.
              </p>
          </div>
  
        </div>
        <div class="accordianBlock">
            <h3 id="l3" #L3>INDEMNIFICATION
  
              </h3>
            <div class="accord-dis">
              <p>You shall defend and indemnify ALkhaleej Group trading as AL KHALEEJ TRAVELS and third party providers and distributors from and against any claim or cause of action brought by or on your behalf in excess of the liability described herein or by third parties as a result of your use of this Website.
                </p>
            </div>
  
          </div>
          <div class="accordianBlock">
              <h3 id="m3" #M3>RELATIONSHIP
                </h3>
              <div class="accord-dis">
                <p>The relationship between ALkhaleej Group trading as AL KHALEEJ TRAVELS and you will be that of independent contractors, and neither of us nor any of our respective officers, agents or employees will be held or construed to be partners, joint ventures, fiduciaries, employees or agents of the other.
                  </p>
              </div>
  
            </div>
            <div class="accordianBlock">
                <h3 id="n3" #N3>GOVERNING LAW
                  </h3>
                <div class="accord-dis">
                  <p>This Agreement and its performance shall be governed by the laws of England. You consent and submit to the exclusive jurisdiction of the state and federal courts located in England, in all questions and controversies arising out of your use of the site and this Agreement.
  
                    </p>
                </div>
  
              </div>
              <div class="accordianBlock">
                  <h3 id="o3" #O3>INSURANCE POLICY
                    </h3>
                  <div class="accord-dis">
                    <p>We recommend that you take out suitable travel insurance to cover (as a minimum) the costs incurred if you cancel your trip, the cost of emergency medical assistance, including repatriation, in the event of an accident or illness whilst you are abroad and it is your responsibility to ensure the insurance you purchase is suitable and adequate for your particular needs. It is your responsibility to comply with the insurance company’s requirements and your duty to disclose to the insurance company all relevant information e.g. pre-existing illnesses.
                      </p>
                  </div>
  
                </div>
  
        <div class="accordianBlock">
            <h3 id="p3" #P3>ASSIGNMENT
              </h3>
            <div class="accord-dis">
              <p>You may not assign, convey, subcontract or delegate your rights, duties or obligations hereunder.
  
  
                </p>
            </div>
  
          </div> 
                <div class="accordianBlock">
              <h3 id="q3" #Q3>MODIFICATION
                </h3>
              <div class="accord-dis">
                <p>ALkhaleej Group trading as AL KHALEEJ TRAVELS may at any time modify these terms and conditions and your continued use of this site will be conditioned upon the terms and conditions in force at the time of your use
  
                  </p>
              </div>
  
            </div>  
                <div class="accordianBlock">
                <h3 id="r3" #R3>ADDITIONAL TERMS
                  </h3>
                <div class="accord-dis">
                  <p>Additional terms and conditions may apply to reservations, purchases of goods and services and other uses of portions of this site, and you agree to abide by such other terms and conditions.
  
                    </p>
                </div>
    
              </div>   
                  <div class="accordianBlock">
                  <h3 id="s3" #S3>SEVERABILITY
                    </h3>
                  <div class="accord-dis">
                    <p>These terms and conditions shall be deemed severable. In the event that any provision is determined to be unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and such determination shall not affect the validity and enforceability of any other remaining provisions.
  
                      </p>
                  </div>
      
                </div>      
                <div class="accordianBlock">
                    <h3 id="t3" #T3>HEADINGS
  
  
                      </h3>
                    <div class="accord-dis">
                      <p>The headings used in these Terms and Conditions are included by convenience only and will not limit or otherwise affect these Terms and Conditions.
  
  
                        </p>
                    </div>
        
                  </div>  
                <div class="accordianBlock">
                      <h3 id="w4" #W4>ENTIRE AGREEMENT
                        </h3>
                      <div class="accord-dis">
                        <p>These terms and conditions contain the entire agreement between us relating to the subject matter hereof, and supersedes any prior understandings or agreements (whether oral or written) regarding the subject matter, and may not be amended or modified except in writing.
                            This is not a binding contract to either party until confirmation that tickets have been issued and credit card payment has been cleared.
  
                          </p>
                      </div>
          
                </div>  
                    
                    <div class="accordianBlock">
                        <h3 id="x3" #X3>CREDIT/DEBIT CARD CHARGES
                          </h3>
                        <div class="accord-dis">
                          <p>Credit card and debit card companies make a charge to process payments on their products.
  
  
  
                            </p>
                        </div>
            
                      </div> 
                    <div class="accordianBlock">
                          <h3 id="y3" #Y3>3rd PARTY PAYMENT
                            </h3>
                          <div class="accord-dis">
                            <p>Where the cardholder is not traveling and making a payment for another passenger, then we require a 3rd party payment form to be completed. This is in order to protect you & AL KHALEEJ TRAVELS from widespread credit card fraud in travel bookings, we reserve the right to refuse payment by credit card over the phone or through a website booking. Any bookings made over the phone or through the website which may be a security risk will be held on option for an agreed time to enable payment by the following means.
                                Payment in Person at our offices, cardholders must present their payment card.
                                Or
                                Cleared funds deposited into our bank account.
                                Normal cancellation charges will apply if we do not receive the 3rd party payment form back.
                              </p>
                          </div>
              
                        </div>   
                        
                        <div class="accordianBlock">
                            <h3 id="z3" #z3>CONTACT WITH YOU
                              </h3>
                            <div class="accord-dis">
                              <p>The email address that you provide with your booking will be used for all future communication with you, including any changes and additional information on your flights and/or hotel bookings. It is therefore your responsibility to ensure that you check your email on a regular basis, and also notify us should your email address change.
  
                                </p>
                            </div>
                
                        </div>  
                      <div class="accordianBlock">
                              <h3 id="A4" #A4>Usage of our Website:
                                </h3>
                              <div class="accord-dis">
                                <p>You agree to use AL KHALEEJ TRAVELS to make reservations and will not make any false or fraudulent reservations and/or transactions.
  
  
  
                                  </p>
                              </div>
                        </div>
  
                        <div class="accordianBlock">
                          <h3 id="b4" #B4>Payments:
                            </h3>
                          <div class="accord-dis">
                            <p>
                                AL KHALEEJ TRAVELS has the right to suspect any transaction if the security requirements and/or procedures are failed to validate the payment method and the passenger will be required to submit the proper documents which will authorize the payment process otherwise AL KHALEEJ TRAVELS has the right to cancel the booking and refund the money to the original form of payment.
  
                                Credit and Debit Cards are subjected to extra charges by the card companies/banks in order to process payments and the charges might be raised in case of paying with different currency due to the daily exchange rate stability.
  
  
  
                              </p>
                          </div>
                    </div>
                    <div class="accordianBlock">
                      <h3 id="c4" #C4>Passport and Visa information/Requirements:
  
  
                        </h3>
                      <div class="accord-dis">
                        <p>It is your own responsibility to fulfil all the required documents that applicable to your itinerary.
  
                            You should confirm that with the proper embassies, authorities and/or consulates. As we do not accept any responsibility in case, you’re unable to travel due to not complying with such requirements.
  
  
  
                          </p>
                      </div>
                </div>
  
                <div class="accordianBlock">
                  <h3 id="d4" #D4>Changes and cancellations:
                    </h3>
                  <div class="accord-dis">
                    <p>Name Changes are NOT permitted by the airlines and/or other suppliers.
  
                        Flights should be used in sequence as originally booked and as shown on your E-ticket.
                        
                        Refunds will be only paid back once we received it from the airlines/supplier.
                        
                        Discounted tickets will lose the value of reduction “amount of discount” in case of cancellation and/or change.
  
  
                      </p>
                  </div>
            </div>
  
  
  
          </div>
        </div>
      </div>
  
    </div>
    </div>

<app-footer></app-footer>