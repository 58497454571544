import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from './shared.module';
import { AppUtilitiesEffects } from './store/app-utilities/app-utilities.effects';
import { AppSettingEffects } from './store/app-setting/app-setting.effects';
import { HotelSearchEffects } from './store/hotel-search/hotel-search.effects';
import { UserEffects } from './store/user/user.effects';
import { FlightSearchEffects } from './store/flight-search/flight-search.effects';
import { reducers, metaReducers } from './store/selectors';
import * as fromState from './store';
import * as fromFlights from './store/flight-search/flight-search.reducer';
import * as fromUtilities from './store/app-utilities/app-utilities.reducer';
import * as fromSettings from './store/app-setting/app-setting.reducer';
import * as fromUser from './store/user/user.reducer';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
    }}),
    FontAwesomeModule,
    StoreModule.forRoot(reducers, {
      metaReducers, 
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    StoreModule.forFeature(fromFlights.flightSearchFeatureKey, fromFlights.reducer),
    StoreModule.forFeature(fromUtilities.appUtilitiesFeatureKey, fromUtilities.reducer),
    StoreModule.forFeature(fromSettings.appSettingFeatureKey, fromSettings.reducer),
    StoreModule.forFeature(fromUser.userFeatureKey, fromSettings.reducer),
    StoreModule.forFeature(fromState.stateFeatureKey, fromState.reducers, { metaReducers: fromState.metaReducers }),
    EffectsModule.forRoot([AppUtilitiesEffects, AppSettingEffects, HotelSearchEffects, UserEffects,FlightSearchEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}