import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { userdata } from '../user-login/user-login.component';
import { MyapiService } from '../../../services/myapi.service';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.css']
})
export class UserSignupComponent implements OnInit {
 signupform:FormGroup
  constructor(
    private myapi:MyapiService,
    private _snackBar: MatSnackBar,
    private Router: Router,
    public translate : TranslateService ) { }
  
  ngOnInit() {
    this.signupform = new FormGroup({
    'Isbase':new FormControl(1),
    'Email': new FormControl('',[Validators.email,Validators.minLength(8),Validators.required]),
    'Password': new FormControl('',[Validators.required,Validators.minLength(8)]),
    'FirstName': new FormControl(''),
    'LastName': new FormControl(''),
    'ImageURL': new FormControl(''),
    'PhoneNumber': new FormControl('',[Validators.required,Validators.minLength(5)]),
    'ConfirmPassword': new FormControl('',[Validators.required,Validators.minLength(8)]),
    'User_Name': new FormControl ('')
  })
  }
  onSubmit() {
    
      this.myapi.signup(this.signupform.value).subscribe(
        (result:userdata)=>{ switch(result.Comment){
          case 0 :{
            console.log('somthing went wrong');
            break
          }
          case 1:{
            sessionStorage.setItem('cls',result.AccessToken);
            this.openSnackBar("Welcome "+result.applicationUser.User_Name,result.Message,"snakSuccess");
            this.toHomepage();
            break
          }
          case -2:{
      
            this.openSnackBar("Sorry user does not exist","Signup faield","snakFaild");
            this.signupform.reset();
            this.signupform.updateValueAndValidity();
            break
          }
          case -1:{
            this.openSnackBar("User already exist","Signup faield",'snakFaild');
          }
        }}
      );
   
}
openSnackBar(message: string, action: string,Staus:string) {
  this._snackBar.open(message, action, {
    duration: 2000,
    panelClass:[Staus]
  });
}
toHomepage() {
  this.Router.navigate(['/']);
}
}
