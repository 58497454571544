import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  sendmessage: FormGroup;
  submitted = false;
  faTwitter=faTwitter;
  faFacebook=faFacebook;
  faInstagram= faInstagram;
  faLinkedin=faLinkedin;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.sendmessage = this.formBuilder.group({

      Name: ['', Validators.required],
      subject: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]

    });
  }
  public get f() { return this.sendmessage.controls; }

 
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.sendmessage.invalid) {
      return;
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.sendmessage.value, null, 4));
    this.sendmessage.reset()
  }

  onReset() {
    this.submitted = false;
    this.sendmessage.reset();
  }

}
