<div class="footerContainer">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="mb-2" style="cursor: pointer;" (click)="goToHome()">
                    <img width="50%" src="assets/img/torest/Group.png">
                </div>

                <div class="links">
                    <a class="nav-link" [routerLink]="['/home']">{{"footer.home" | translate}}</a> 
                    <span class="vertivalLine"></span>
                    <a class="nav-link" [routerLink]="['/aboutUs']">{{"footer.aboutTorest" | translate}}</a>
                    <span class="vertivalLine"></span>
                    <a class="nav-link" [routerLink]="['/Contact']">{{"footer.cotactUs" | translate}}</a>
                    <span class="vertivalLine"></span>
                    <a class="nav-link" [routerLink]="['/Privacy']">{{"footer.TermofUse" | translate}}</a>
                    <span class="vertivalLine"></span>
                    <a class="nav-link" [routerLink]="['/TermOfUse']">{{"footer.PrivacyPolicy" | translate}}</a>
                </div>

                <div class="rights">{{"footer.rights" | translate}}</div>
            </div>

            <div class="col-12 col-lg-3 mt-1">
                <!-- <div class="appTitle">{{"footer.apps" | translate}}</div>

                <div class="apps">
                    <img class="appImg" src="assets/img/torest/appleStore.svg">
                    <img class="appImg" src="assets/img/torest/googleStore.svg">
                </div> -->
            </div>

            <div class="col-12 col-lg-3 mt-3" style="text-align: center;">
                <div class="socialMedia">
                    <a class="socialContain" href="https://www.facebook.com/KhaleejGate0"><img class="socialImg" src="assets/img/torest/facebook.svg"></a>
                    <a class="socialContain" href="https://www.linkedin.com/"><img class="socialImg" src="assets/img/torest/linkedIn.svg"></a>
                    <a class="socialContain" href="https://twitter.com/?lang=en"><img class="socialImg" src="assets/img/torest/twitter.svg"></a>
                </div>
            </div>
        </div>
    </div>
</div>