import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { CurrencyModule } from "../../modules/currency/currency.module";
import { PointOfsaleModule } from "../../modules/point-ofsale/point-ofsale.module";
import {
  updateAppCurrency,
  updateAppLang,
  updateAppPOS,
} from "../../store/app-setting/app-setting.actions";
import { loadUtilitysCurrency } from "../../store/app-utilities/app-utilities.actions";
import {
  selectAllCurrency,
  selectAppCurrency,
  selectAppLang,
  selectAppPOS,
  selectSettingsFeature,
} from "../../store/selectors";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input() isRestricted: boolean = false;

  faBars= faBars;
  @Output() switch: EventEmitter<boolean> = new EventEmitter();
  pointofSale: string;
  flags: CurrencyModule[] = [];
  logedin: boolean = false;
  public isMenuCollapsed = true;
  public isMenuCollapsed1 = true;
  appSetting$ = this.store.select(selectSettingsFeature);
  POS$ = this.store.select(selectAppPOS);
  lang$ = this.store.select(selectAppLang);
  lang: string = this.translate.currentLang;
  selectedCurrency$: Observable<CurrencyModule> =
    this.store.select(selectAppCurrency);
  Currencies$: Observable<CurrencyModule[]> =
    this.store.select(selectAllCurrency);
  currencyValue: any;
  showDetails : boolean = false

  private subscription: Subscription = new Subscription();

  allPOS : PointOfsaleModule[] = [
    {country:'EG' , country_name:'Egypt'  , country_nameAr:'مصر' , city:'Cairo' ,airport:'CAI'},
    {country:'KW' , country_name:'Kuwait' , country_nameAr:'كويت' , city:'Kuwait' ,airport:'KWI'}
  ]

  selectedPOSEn:string = 'Kuwait'
  selectedPOSAr:string = 'كويت'

  defaultPOS:PointOfsaleModule = {country:'KW' , country_name:'Kuwait' , country_nameAr:'كويت' , city:'kuwait'}

  inHome: boolean = true;

  constructor(
    public translate: TranslateService,
    private readonly store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.checkHomePage();

    this.POS$.pipe(take(3)).subscribe((res=>{
      if(res){
        var index = this.allPOS.findIndex(v=>v.country == res.country)
        if(index != -1){
          this.selectedPOSEn = this.allPOS[index].country_name;
          this.selectedPOSAr = this.allPOS[index].country_nameAr;
          this.store.dispatch(updateAppPOS({POS:this.allPOS[index]}))
        }
        else{
          this.store.dispatch(updateAppPOS({POS:this.defaultPOS}))
        }
      }
      
    }))

    this.store.dispatch(loadUtilitysCurrency());
    if (sessionStorage.getItem("type") == "flights") {
    }
    if (localStorage.getItem("country")) {
      this.subscription.add(
        this.POS$.subscribe((result) => {
          if(result){
            this.pointofSale = result.country;
          }
          
        })
      );
    }

    if (
      this.translate.currentLang == "ar" ||
      localStorage.getItem("lang") == "ar"
    ) {
      this.switchtoAr();
    }

    if (sessionStorage.getItem("cls")) {
      this.logedin = true;
    }
    this.lang$.subscribe((v) => {
      this.lang = v;
      this.translate.use(this.lang);
    });
  }
  toggleDetails(){
    this.showDetails = !this.showDetails
  }
  /**
   * change the current languague to english
   */
  switchtoEn() {
    this.store.dispatch(updateAppLang({ Lang: "en" }));
  }

  /**
   * change the current languague to arabic
   */
  switchtoAr() {
    this.store.dispatch(updateAppLang({ Lang: "ar" }));
  }

  /**
   * change the current currency
   */
  changeCurrency(curr: CurrencyModule) {
    this.store.dispatch(updateAppCurrency({ Currency: curr }));
  }

  // unsubscribe for all observalbles
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * detect the current languague and change to the selected one
   */
  langSwitch(lang: string) {
    this.lang = lang;
    this.lang == "en" ? this.switchtoEn() : this.switchtoAr();
  }

  goToLogin() {
    this.router.navigate(["/login"]);
  }

  goToSignUp() {
    this.router.navigate(["/signup"]);
  }

  goToProfile() {
    this.router.navigate(["/profile"]);
  }

  goToHome() {
    this.router.navigate(["/home"]);
  }

  /**
   * check if we are in the home page to apply the correct style of the header..
   */
  checkHomePage() {
    if (this.router.url == "/home") {
      this.inHome = true;
    } else {
      this.inHome = false;
    }
  }

  changePOS(pos:any){
    this.selectedPOSEn = pos.country_name;
    this.selectedPOSAr = pos.country_nameAr;
    this.store.dispatch(updateAppPOS({POS:pos}))
  }

  checkPOS(){
    this.POS$.pipe(take(1)).subscribe((res=>{
      if(res){
        var index = this.allPOS.findIndex(v=>v.country_name == res.country_name)
        if(index != -1){
          this.selectedPOSEn = this.allPOS[index].country_name;
          this.selectedPOSAr = this.allPOS[index].country_nameAr;
          this.store.dispatch(updateAppPOS({POS:this.allPOS[index]}))
        }

        else{
          this.store.dispatch(updateAppPOS({POS:this.defaultPOS}))
        }

      }
    }))
  }
}
