<div class="privacy">
    <app-header class="header"></app-header>
</div>

<div class="wraber">
  <div class="container">
    <div class="row title">
      <h1>Privacy Policy</h1>
    </div>
    <div class="row align-self-center  justify-content-center">
  
      <div class="col-xs-12 col-sm-8 col-md-10 align-self-center justify-content-center">
        <div class="boxNewGrid privcy-terms-content hotelTerms">
          <div class="accordianBlock">
            <h3 >Our privacy </h3>
          
            <div class="accord-dis">
              <p>As a customer of ALkhaleej Group trading as AL KHALEEJ TRAVELS, you are in complete control of your travel planning needs. This includes controlling the use of information you provide ALkhaleej Group trading as AL KHALEEJ TRAVELS. When you reserve or purchase travel services through ALkhaleej Group trading as AL KHALEEJ TRAVELS, we provide information about you to the airline, car rental agency, hotel, ALkhaleej Group trading as AL KHALEEJ TRAVELS or other involved third party to ensure the successful fulfilment of your travel arrangements. We also use information you provide during registration or as part of the reservation process to customize the content of our site to meet your specific needs and to make product improvements to ALkhaleej Group trading as AL KHALEEJ TRAVELS. ALkhaleej Group trading as AL KHALEEJ TRAVELS will send you an e-mail message confirming each reservation you transact online. We do not sell individual customer names or other private profile information to third parties. As a member or contest entrant you will occasionally receive updates from us about fare sales in your area, special offers, new ALkhaleej Group trading as AL KHALEEJ TRAVELS services and noteworthy news. We hope you will find these updates interesting and informative. ALkhaleej Group trading as AL KHALEEJ TRAVELS reserves the right to limit membership to those who will accept e-mails. We respect and will continue to respect the privacy of our customers who use ALkhaleej Group trading.
              </p>
            </div>
          </div>
          <div class="accordianBlock">
              <h3 >DATA PROTECTION AND PRIVACY POLICY
                </h3>
            
              <div class="accord-dis">
                <p>Privacy and Data Protection is a huge priority to our company and we respect and understand the importance. We preserve your personal information when visiting our site or communicate electronically with us and we commit to safeguard your personal information. <br>

                    We tend to collect information for the below:<br><br>
                    
                    Contact details & Full Passengers names including date of birth, telephone number, address and email address
                    Personal Information that is completed on our website when purchasing a product or service from us 
                    Personal Information provided to us when you communicate with us through our contact forms or speak to our customer service agents
                    Personal information such as full names, address, contact numbers & email address for Job applicants who have applied for vacancies.<br>
                    If you subscribed to our Newsletter to receive weekly updates & offers on our products 
                    Details of your visits to our website to help gather information to provide an optimum service. We use cookies to track movement around our site such as traffic data, location data & also other communication data.
                    There are certain circumstances where we need to collect sensitive personal data. It is rare but in these cases we need to oblige by the service provider rules & regulations. On notes like this, we act in your interest and only prepare this data having had your consent where necessary. <br>
                    
                     
                    
                    Please note that we may have to share data with our worldwide trusted partners, such as Airlines, Hoteliers, Car Hire & Airport Parking services & other suppliers in order to arrange and confirm your booking. We may also have to disclose information to third parties in relation to concluding your product purchased & support services where required. We take reasonable steps to ensure your data is secure and any transactions from our website will be encrypted to ensure highest levels of safety. At times, we need to disclose your information required by the Law and a request is made by a legal authority. Other than this, we do not share any further information with any other companies.<br>
                    
                     
                    
                    The transmission of information via the internet which is not encrypted can at any time result to unsecured data, therefore we cannot guarantee the security of this data and this will be at your own risk. Any relevant passwords related to your booking given to you by us, our partners or suppliers is solely your responsibility to keep this confidential.<br>
                    
                     
                    
                    On a weekly note, we send you our Email Newsletter which consists of special travel offers, product update, competitions and forthcoming events. We use the email address you share with us for this newsletter and this is held on our secured database. If you do not wish to receive these newsletters, you may ask us in writing not to receive it or unsubscribe on the link provided on the Newsletter email. Your email address will automatically be removed from the list.<br>
                    
                     
                    
                    As per our general policy, we monitor and record telephone calls, customer transactions and activities on our website to help improve our service and meet customer satisfaction. All recordings remain our sole property. <br>
                    
                    In order to comply with the law & regulations, from time to time we update changes to our privacy policy which is reflected on this page. We highly suggest that you read this statement so that you are aware on how we use and gather information.<br>
                </p>
              </div>
            </div>
            <div class="accordianBlock">
                <h3 >COOKIES </h3>
              
                <div class="accord-dis">
                  <p>We use cookies which are small text files that are downloaded to your computer (or other electronic device) to help us improve our website, develop new methods and our services. Cookies enable us to gather information, track movement on our site, carry out research and statistical analysis and show details of online activity to help improve our content and services. Please be assured that we do not collect or store any of your passwords or sensitive data in this process.
                  </p>
                </div>
              </div>
       
        </div>
      </div>
    </div>

  </div>



  <app-footer></app-footer>