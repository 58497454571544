import { updateSearchCretaria } from "./flight-search.actions";
import { MyapiService } from "src/app/services/myapi.service";
import { mergeMap, map, catchError, retry, take, toArray } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as flightActions from "../flight-search/flight-search.actions";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { airItineraries, flightFilter, FlightSearchResult, itinTotalFare } from "../../interfaces/flight-search-result";
import { FlightServiceService } from "../../services/flight-service.service";
import { signatureMapedItinerary } from "../../interfaces/meta-flight";

@Injectable()
export class FlightSearchEffects {
  constructor(
    private actions$: Actions,
    private apiService: MyapiService,
    private store: Store,
    private flightS:FlightServiceService
  ) {}

  loadFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadFlightSearchs),
      mergeMap((searchCrtariea) =>
        this.apiService.searchFlight(searchCrtariea.search).pipe(
          map((v) => {
            this.store.dispatch(
              updateSearchCretaria({ searchData: searchCrtariea.search })
            );

            return flightActions.loadFlightSearchsSuccess({ data: v });
          }),
          catchError((err) => {
            this.apiService.openSnackBar("loadFlightSearchsFailure"," faild","snakFaild");
            console.log(err)
            return of(flightActions.loadFlightSearchsFailure(err));

          })
        )
      )
    )
  );
  loadFlightsLowestFare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadFlightSearchsLowestFare),
      mergeMap((searchCrtariea) =>
        this.apiService.searchFlight(searchCrtariea.search).pipe(
          map((v) => {
            this.store.dispatch(
              updateSearchCretaria({ searchData: searchCrtariea.search })
            );
            return flightActions.loadFlightSearchsLowestFareSuccess({ data: getTheLowestFare(v.airItineraries) });
          }),
          catchError((err) => {
            console.log(err)
            return of(flightActions.loadFlightSearchsLowestFareFailure(err));
          })
        )
      )
    )
  );
  filterFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.filterFlight),
      map((ac) => {
       return flightActions.filterFlightSuccess({
        //  airItineraries:orgnize(ac.airItineraries)
          airItineraries: orgnize(ac.airItineraries.filter(v =>
              filterFlighWithPrice(v, ac.filter)                       &&
              filterFlighWithDepartionTime(v, ac.filter, convertToMin) &&
              filterFlighWithArrivalTime(v,ac.filter , convertToMin)   &&
              filterWithFlexibleTickets(v,ac.filter)                   &&
              filterFlighWithDuration(v, ac.filter)                    &&
              filterFlightWithNumberofStopsFunction(v, ac.filter)      &&
              filterWithExperience(v,ac.filter)                        &&
              // filterFlighWithReturnTime(v, ac.filter, ac.roundTrip, convertToMin) &&
              filterFlightWithAirlineFunction(v, ac.filter)            &&
              completeTripOnSameAirline(v,ac.filter)                   &&
              filterWithBookingSites(v,ac.filter)))          
            })})
    )
  );

  GetFareRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadFares),
      mergeMap((param) =>
        this.apiService
          .fareRules(param.searchid, param.sequenceNum, param.providerKey)
          .pipe(
            map((fares) => {
              return flightActions.loadFaresSuccess({ data: fares });
            }),
            catchError((err) => {
              this.apiService.openSnackBar("loadFaresFailure"," faild","snakFaild");

              return of(flightActions.loadFaresFailure(err));
            })
          )
      )
    )
  );

  GetSelectedFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadSelectedFlight),
      mergeMap((param) =>
        this.apiService
          .getSelectedFlight(
            param.searchid,
            param.sequenceNum,
            param.providerKey
          )
          .pipe(
            map((SelectdeFlights) => {
              return flightActions.loadSelectedFlightSuccess({
                data: SelectdeFlights,
              });
            }),
            catchError((err) => {
              this.apiService.openSnackBar("loadSelectedFlightFailure"," faild","snakFaild");

              return of(flightActions.loadSelectedFlightFailure(err));
            })
          )
      )
    )
  );

  GetOfflineServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadOfflineSrvices),
      mergeMap((param) =>
        this.apiService.offlineServices(param.searchid, param.POS).pipe(
          map((offlineService) => {
            return flightActions.loadOfflineSrvicesSuccess({
              data: offlineService,
            });
          }),
          catchError((err) => {
            this.apiService.openSnackBar("loadSelectedFlightFailure"," faild","snakFaild");

            return of(flightActions.loadSelectedFlightFailure(err));
          })
        )
      )
    )
  );

  saveBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.SaveBooking),
      take(1),
      mergeMap((param) =>
        this.apiService
          .saveBooking(
            param.searchid,
            param.sequenceNum,
            param.body,
            param.pkey
          )
          .pipe(
            mergeMap((v) =>
              v.hgNumber
                ? this.apiService
                  .CheckFlightValidation(
                    v.hgNumber,
                    param.lang,
                    param.searchid,
                    param.sequenceNum,
                    param.pkey
                  )
                  .pipe(
                    retry(3),
                    mergeMap(
                      (val) =>
                      (val.status = "Valid"
                        ? this.apiService
                          .GetPaymentView(
                            param.ip,
                            param.iplocation,
                            v.hgNumber,
                            param.searchid,
                            "",
                            param.selectedServices
                          )
                          .pipe(
                            map((link) => {
                              // window.location.href = link.link;
                              return flightActions.SaveBookingSuccess({
                                data: link.link,
                              })
                            }
                            ),
                            catchError((err) => {
                              return of(
                                flightActions.SaveBookingFailure(err)
                              );
                            })
                          )
                        : of(
                          flightActions.SaveBookingFailure({
                            err: "FAildToSaveBooking",
                          })
                        ))
                    ),
                    catchError((err) => {
                      this.apiService.openSnackBar("SaveBookingFailure"," faild","snakFaild");

                      return of(flightActions.SaveBookingFailure(err));
                    })
                  )
                : of(
                  flightActions.SaveBookingFailure({
                    err: "FAildToSaveBooking",
                  })
                )
            ),
            catchError((err) => {
              this.apiService.openSnackBar("SaveBookingFailure"," faild","snakFaild");

              return of(flightActions.SaveBookingFailure(err));
            })
          )
      )
    )
  );

  IntFilterFlight$ = createEffect(() =>
  this.actions$.pipe(
    ofType(flightActions.intFilterFlight),
    map((ac) => {
     return flightActions.intFilterFlightSuccess({
       airItineraries:orgnize(ac.airItineraries),
       sortair:ac.airItineraries
        })})
  )
);

// sortIntValue$ =createEffect(() =>
// this.actions$.pipe(
//   ofType(flightActions.intFilterFlightSuccess),
//   map((ac) => {
//    return flightActions.sortFlight({
//      airItineraries:ac.sortair,
//      sortType:1
//       })})
// )
// );

  sortFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.sortFlight),
      map((param) => {
        return flightActions.sortFlightSuccess({airItineraries:orgnize(sortMyResult(param.sortType , param.airItineraries))})
      })
    )
  );


}

type filterFlightWithAirlineFunction = (airItineraries: signatureMapedItinerary, filter: flightFilter) => boolean;
type filterFlightWithNumberofStopsFunction = (airItineraries: signatureMapedItinerary, filter: flightFilter) => boolean;
type filterFlighWithDepartionTime = (airItineraries: signatureMapedItinerary, filter: flightFilter, timeTOmin: timeToMinits) => boolean;
type filterFlighWithArrivalTime = (airItineraries: signatureMapedItinerary, filter: flightFilter, timeTOmin: timeToMinits) => boolean;
type filterFlighWithReturnTime = (airItineraries: signatureMapedItinerary, filter: flightFilter, isRound: boolean, timeTOmin: timeToMinits) => boolean;
type filterFlighWithDuration = (airItineraries: signatureMapedItinerary, filter: flightFilter) => boolean;
type filterFlighWithPrice = (airItineraries: signatureMapedItinerary, filter: flightFilter) => boolean;
type timeToMinits = (time: string) => number;
type orgnizeArr = (airItineraries: signatureMapedItinerary[]) => signatureMapedItinerary[][];
type filterCompose = (airItineraries: signatureMapedItinerary[], filter: flightFilter, isRound: boolean, fn1: filterFlighWithPrice, fn2: filterFlightWithNumberofStopsFunction, fn3: filterFlighWithDepartionTime, fn4: filterFlighWithReturnTime, fn5: filterFlighWithDuration, fn6: filterFlighWithPrice, fn7: timeToMinits, fn8: orgnizeArr) => airItineraries[][];

export const filterFlighWithPrice: filterFlighWithPrice = (flight, filter) => flight.itinTotalFare.amount >= filter.priceMin && flight.itinTotalFare.amount < filter.priceMax;

export const filterFlighWithDuration: filterFlighWithDuration = (flight, filter) => flight.totalDuration >= filter.durationMin && flight.totalDuration < filter.durationMax;

export const filterFlighWithReturnTime: filterFlighWithReturnTime = (flight, filter, round, convertToMin) => round ? convertToMin(flight.allJourney.flights[1].flightDTO[0].departureDate) >= filter.returnMin && convertToMin(flight.allJourney.flights[1].flightDTO[0].departureDate) < filter.returnMax : true;

export const filterFlighWithDepartionTime: filterFlighWithDepartionTime = (flight, filter, convertToMin) => convertToMin(flight.allJourney.flights[0].flightDTO[0].departureDate) >= filter.depatingMin && convertToMin(flight.allJourney.flights[0].flightDTO[0].departureDate) < filter.departingMax;
export const filterFlighWithArrivalTime: filterFlighWithArrivalTime = (flight, filter, convertToMin) => convertToMin(flight.allJourney.flights[0].flightDTO[flight.allJourney.flights[0].flightDTO.length - 1].arrivalDate) >= filter.arrivingMin && convertToMin(flight.allJourney.flights[0].flightDTO[flight.allJourney.flights[0].flightDTO.length - 1].arrivalDate) < filter.arrivingMax;

//  take date string return number
export const convertToMin = function (time: string): number {
  let date = time;
  let T = date.indexOf('T');
  let h = date.slice(T + 1);
  let hr = +h.slice(0, 2) * 60;
  let m = +h.slice(3, 5);
  let tm = hr + m;
  return tm
}

// export const filterFlightWithNumberofStopsFunction: filterFlightWithNumberofStopsFunction = (airItineraries: signatureMapedItinerary, filter: flightFilter) => airItineraries.allJourney.flights.map(v => filter.stops.indexOf(v.stopsNum) != -1).reduce((sum, next) => sum && next, true);
export const filterFlightWithAirlineFunction: filterFlightWithAirlineFunction = (airItineraries: signatureMapedItinerary, filter: flightFilter) => filter.airlines.length > 0 ?filter.airlines.indexOf(airItineraries.allJourney.flights[0].flightDTO[0].flightAirline.airlineName) != -1 :true;
export const orgnize: orgnizeArr = function (array: signatureMapedItinerary[]): signatureMapedItinerary[][] {
  console.log(array.length,'orgnize')
  let out :signatureMapedItinerary[][]=[];
  let remain:signatureMapedItinerary[] =array;
  let i = 0;
  while (remain.length >0 || !remain) {
      out.push(remain.filter((v,i,a)=>v.allJourney.flights[0].flightDTO[0].flightAirline.airlineCode ===a[0].allJourney.flights[0].flightDTO[0].flightAirline.airlineCode && v.itinTotalFare.amount === a[0].itinTotalFare.amount));
      remain = remain.filter((v,i,a)=>v.allJourney.flights[0].flightDTO[0].flightAirline.airlineCode !=a[0].allJourney.flights[0].flightDTO[0].flightAirline.airlineCode ||v.itinTotalFare.amount != a[0].itinTotalFare.amount);
    i = i +1
  }
  console.log(out,'RETURN THE OUTING');
 return out

}


export function filterWithFlexibleTickets(flight: signatureMapedItinerary, filter: flightFilter) :boolean{
  if(filter.flexibleTicket[0] && !filter.flexibleTicket[1]){
    if(flight.isRefundable){
      return true
    }
    else{
      return false
    }
  }

  else if(!filter.flexibleTicket[0] && filter.flexibleTicket[1]){
    if(flight.isRefundable){
      return false
    }
    else{
      return true
    }
  }

  else if(!filter.flexibleTicket[0] && !filter.flexibleTicket[1]){
    return true
  }

  else if(filter.flexibleTicket[0] && filter.flexibleTicket[1]){
    return true
  }else{
    return false
  }
}

export function filterWithExperience(flight: signatureMapedItinerary , filter: flightFilter):boolean{
  if(filter.experience[0] && !filter.experience[1]){
    if(flight.overNight == 0){
      return true
    }
    else{return false}
  }

  else if(filter.experience[1] && !filter.experience[0]){
    if(flight.stopsTime < 4){
      return true
    }
    else{return false}
  }

  else if(filter.experience[1] && filter.experience[0]){
    if(flight.overNight == 0 && flight.stopsTime < 4 ){
      return true
    }
    else{return false}
  }

  else if(!filter.experience[1] && !filter.experience[0]){
    return true
  }
  else{
    return false
  }
}

export function filterWithBookingSites(flight: signatureMapedItinerary , filter: flightFilter):boolean{
  let siteFound : boolean = false;
  if(filter.bookingSites.length == 0){
    return true
  }
  for(var i  = 0 ; i < filter.bookingSites.length ; i++){
    for(var j = 0 ; j < flight.options.length ; j++){
      if(flight.options[j].otaName == filter.bookingSites[i]){
        siteFound = true
      }
    }
  }

  if(siteFound){return true}
  else{return false}
}

export function filterFlightWithNumberofStopsFunction(flight: signatureMapedItinerary , filter: flightFilter):boolean{
  let stopFlage : boolean = true;
  if(filter.stops[0] == 0 && filter.stops.length == 1){
    for(var i = 0 ; i < flight.allJourney.flights.length ; i++){
      if(flight.allJourney.flights[i].stopsNum !=0){
        stopFlage = false
      }
    }
  }

  else if(filter.stops[0] == 0 && filter.stops[1] == 1){
    for(var i = 0 ; i < flight.allJourney.flights.length ; i++){
      if(flight.allJourney.flights[i].stopsNum > 1){
        stopFlage = false
      }
    }
  }

  else if(filter.stops[0] == 0 && filter.stops[1] == 2){
    for(var i = 0 ; i < flight.allJourney.flights.length ; i++){
      if(flight.allJourney.flights[i].stopsNum == 1){
        stopFlage = false
      }
    }
  }

  else if(filter.stops[0] == 1){
    for(var i = 0 ; i < flight.allJourney.flights.length ; i++){
      if(flight.allJourney.flights[i].stopsNum != 1){
        stopFlage = false
      }
    }
  }

  else if(filter.stops[0] == 2){
    for(var i = 0 ; i < flight.allJourney.flights.length ; i++){
      if(flight.allJourney.flights[i].stopsNum < 2){
        stopFlage = false
      }
    }
  }

  else{
    stopFlage = true
  }

  
  return stopFlage
}

export function completeTripOnSameAirline(flight: signatureMapedItinerary , filter: flightFilter):boolean{
  if(!filter.sameAirline){
    return true
  }
  else{
    let airlineChange = true
    let firstAirline = flight.allJourney.flights[0].flightDTO[0].flightAirline.airlineName

    let flightAirlines:string[][]
    flightAirlines = flight.allJourney.flights.map(v=>{
      return v.flightDTO.map(f=>{
        return f.flightAirline.airlineName
      })
    })

    for(var i = 0 ; i < flightAirlines.length ; i++){
      for(var j = 0 ; j < flightAirlines[i].length ; j++){
        if(flightAirlines[i][j] != firstAirline){
          airlineChange = false;
        }
      }
    }
    return airlineChange;
  }
}

export function sortMyResult(type:number , grupedData:signatureMapedItinerary[][]):signatureMapedItinerary[]{
  let data = grupedData.reduce((p,c)=>{return p.concat(c)});
    if(type == 1){
      return [...data].sort((a,b) => {return a.itinTotalFare.amount - b.itinTotalFare.amount})
    }
    if(type == 2){
      return [...data].sort((a,b) => {return a.totalDuration - b.totalDuration })
    }
    if(type ==  3){ 
      return [...data].sort((a,b) => {return <any>new Date(a.deptDate) - <any>new Date(b.deptDate) })
    }
    if(type == 4){
      return [...data].sort((a,b) => {return <any>new Date(b.deptDate) - <any>new Date(a.deptDate) })
    }
    if(type == 5){
      return [...data].sort((a,b)=>{return <any>new Date(a.allJourney.flights[1].flightDTO[0].departureDate) - <any>new Date(b.allJourney.flights[1].flightDTO[0].departureDate) })
    }

    if(type == 6){
      return [...data].sort((a,b)=>{return <any>new Date(b.allJourney.flights[1].flightDTO[0].departureDate) - <any>new Date(a.allJourney.flights[1].flightDTO[0].departureDate) })
    }

    if(type == 7){
      return [...data].sort((a,b)=>{return a.experiance - b.experiance})
    }
    return [...data]
   }

   export function getTheLowestFare(data:airItineraries[]):itinTotalFare{
    let lowest = [...data];
    lowest = lowest.sort((a,b)=>{return a.itinTotalFare.amount - b.itinTotalFare.amount});
    return lowest[0].itinTotalFare;
   }

   




