import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CurrencyModule } from '../modules/currency/currency.module';
import { CurruncyServiceService } from '../services/curruncy-service.service';
import { selectAppCurrency, State } from '../store/selectors';


@Pipe({
  name: 'exchange',
  pure:false
})
export class ExchangePipe implements PipeTransform {
  currentCurruncy:CurrencyModule;
  currentCurruncy$:Observable<CurrencyModule> = this.store.select(selectAppCurrency);
  constructor(private curruncy:CurruncyServiceService,private readonly store: Store<State>){
    this.currentCurruncy$.subscribe((v)=>{this.currentCurruncy = v;});
 
  }

  transform(value: any, args?: any): any {
    if(!value || !args) {
      return value;
    }
    else {
      if(args == "value" && this.currentCurruncy.Currency_Code == 'KWD') {
        let total = value * this.currentCurruncy.rate ;
        return parseFloat((Math.round(total*1000)/1000).toFixed(3));
      }
      if(args == "value" && this.currentCurruncy.Currency_Code != 'KWD') {
        let total = value * this.currentCurruncy.rate;
        return parseFloat((Math.round(total*100)/100).toFixed(2));
      }
      if(args == "code") {
        return this.currentCurruncy.Currency_Code;
      }
    }

  }
  

}

