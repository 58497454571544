<app-header></app-header>
<footer id="footer" class="section-bg">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="row">
                        <div class="col-sm-6"></div>

                        <div class="col-sm-12">
                            <div class="footer-links">
                                <h4>{{"contact.contactUs"|translate}}</h4>
                                <p>{{'contact.address' | translate}}<br>
                                  
                                  <strong>{{"contact.phone"|translate}}:</strong>+965 67057419/ +965 65855786/ +965 67066602<br>
                                  <strong>{{"contact.email"|translate}}:</strong> Help@KhaleejGate.com<br>
                                </p>
                              </div>
              
                              <div class="social-links">
                                <a href="https://twitter.com" class="twitter">
                                    <fa-icon [icon]=" faTwitter" style="color: #fff"></fa-icon>
                                </a>
                                <a href="https://www.facebook.com/KhaleejGate0/" class="facebook">
                                    <fa-icon [icon]=" faFacebook" style="color: #fff"></fa-icon>
                                </a>
                                <a href="https://www.instagram.com" class="instagram">
                                    <fa-icon [icon]=" faInstagram" style="color: #fff"></fa-icon>
                                </a>
                                <a href="https://www.linkedin.com" class="linkedin">
                                    <fa-icon [icon]=" faLinkedin" style="color: #fff"></fa-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="form">
                        <h4>{{ "contact.sendMsg" | translate }}</h4>
                        <!-- <form  [formGroup]="sendmessage"  action="" method="post" role="form" class="contactForm" >
              <div class="form-group">
                <input formControlName="name" type="text" name="name" class="form-control" id="name" placeholder='{{"contact.name"|translate}}' data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <input formControlName="email" type="email" class="form-control" name="email" id="email" placeholder='{{"contact.email"|translate}}'  data-rule="email" data-msg="Please enter a valid email" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <input formControlName="subject" type="text" class="form-control" name="subject" id="subject" placeholder='{{"contact.subject"|translate}}' data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validation"></div>
              </div>
              <div class="form-group">
                <textarea class="form-control" formControlName="message" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder='{{"contact.msg"|translate}}'></textarea>
                <div class="validation"></div>
              </div>

              <div id="sendmessage">{{"contact.sucess"|translate}}</div>
              <div id="errormessage"></div>

              <div class="text-center"><button type="submit" title="Send Message" >{{"contact.send"|translate}}</button></div>
            </form> -->

                        <form [formGroup]="sendmessage" (ngSubmit)="onSubmit()">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <input type="text" placeholder="{{ 'contact.name' | translate }}"
                                        formControlName="Name" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f['Name'].errors }" />
                                    <div *ngIf="submitted && f['Name'].errors" class="invalid-feedback">
                                        <div *ngIf="f.Name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <input type="text" formControlName="email"
                                        placeholder="{{ 'contact.email' | translate }}" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                        <div *ngIf="f['email'].errors.required">Email is required</div>
                                        <div *ngIf="f['email'].errors.email">
                                            Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12">
                                    <input type="text" placeholder="{{ 'contact.subject' | translate }}"
                                        formControlName="subject" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }" />
                                    <div *ngIf="submitted && f['subject'].errors" class="invalid-feedback">
                                        <div *ngIf="f.subject.errors.required">
                                            subject is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <textarea class="form-control" formControlName="message" name="message" rows="6"
                                    data-rule="required" data-msg="Please write something for us"
                                    [ngClass]="{ 'is-invalid': submitted && f['message'].errors }"
                                    placeholder="{{ 'contact.msg' | translate }}"></textarea>
                                <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required">
                                        message is required
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="text-center">
                  <button class="btn btn-primary mr-1">Register</button>
               
              </div> -->
                            <div class="text-center">
                                <button class="btn btn-primary mr-1" type="submit" title="Send Message">
                                    {{ "contact.send" | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-footer></app-footer>
<!-- #footer -->