export const environment = {
    production: true,
    baseCurrency: 'KWD',
    endPoints: {
      searchflow:  'https://wegosearch.khaleejgate.com',
      BookingFlow:  'https://wegobook.khaleejgate.com',
      FareRules:  'https://wegoprovider.khaleejgate.com',
      asm:  'https://backofficeapi.khaleejgate.com',
      Apihotels:  "https://hotels.khaleejgatecom",
      hotelprepay: 'https://prepayapi.khaleejgate.com',
      users:  'https://usersapi.khaleejgate.com',
      admin:  'https://adminapi.khaleejgate.com/',
      getDPayment:  'https://adminapi.khaleejgate.com/',
      bookHotels: "https://hotels.khaleejgate.com",
      hotelPrepay: 'https://prepayapi.khaleejgate.com',
      backOffice: 'https://backofficeapi.khaleejgate.com',
      FlightTop:'https://flightsearch.khaleejgate.com',
      offers: {
        getAll: 'http://41.215.243.36:7893/api/GetAllOffersAPI?POS=',
        getByID: 'http://41.215.243.36:7893/api/GetOfferByIdAPI?OfferId=',
        BookOffer: "http://41.215.243.36:7895/api/BookOffer",
      }
    }
  };
  