import { EventEmitter, Injectable } from "@angular/core";
import { CurrencyModule } from "../modules/currency/currency.module";
import { FlightsInfoModule } from "../modules/flights-info/flights-info.module";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, mergeMap, retry, take } from "rxjs/operators";
import { CitiesModule } from "../modules/cities/cities.module";
import { Observable } from "rxjs";
import { PointOfsaleModule } from "../modules/point-ofsale/point-ofsale.module";
import { SearchHoteltModule } from "../modules/search-hotel/search-hotel.module";
import { GetHotelModule } from "../modules/search-hotel/get-hotels.module";
import { HotelRoom } from "../modules/HotelRooms/HotelRooms";
import { RequiredHotel } from "../modules/HotelBooking/RequiredHotelData";
import { airItineraries, FareRules, OfflineServiceModule, PassengersModule,FlightSearchResult,AirPorts, Selectedflight } from "../interfaces/flight-search-result";

import { SearchFlightModule } from "../modules/search-flight/search-flight.module";
import { environment } from "../../environments/environment";
import { CountriescodeModule } from "../modules/countriescode/countriescode.module";
import { MatSnackBar } from "@angular/material/snack-bar";
// import { familymember } from "../user/family-members/family-members.component";
import { OfferDTO } from "../interfaces/offers";
import { addExperiance, addSignature, addStopTime, createSignatureString, getmetaSearchResult, groupBysignature, mapSignature, metaFlightRequest, metaFlightResponce, metaSearchResult, overNight } from "../interfaces/meta-flight";
import { familymember } from "../components/users/family-members/family-members.component";
import { Cobon } from "../interfaces/cobon";
import { TopCityFlight } from "../interfaces/Home-content";
export interface ipA {
  ip: string;
}
export interface guest {
  adult: number;
  child: number[];
}
@Injectable({
  providedIn: "root",
})
export class MyapiService {
  backOffice: string = environment.endPoints.backOffice;
  Apihotels: string = environment.endPoints.Apihotels;
  hotelPrepay: string = environment.endPoints.hotelPrepay;
  FareRules: string = environment.endPoints.FareRules;
  searchFlow: string = environment.endPoints.searchflow;
  BookingFlow: string = environment.endPoints.BookingFlow;
  users: string = environment.endPoints.users;
  admin: string = environment.endPoints.admin;
  FlightTop:string =environment.endPoints.FlightTop
  resultUpdate:Observable<any>;
  constructor(private httpClient: HttpClient , private _snackBar: MatSnackBar) {}

  flightInfoFormatter(array: FlightsInfoModule[]) {
    //return string of flights in KWI-CAI-March%2015%202019_ format
    let FlightsInfoArray: string = "";
    for (let element of array) {
      let fligt: string =
        element.departingCity +
        "-" +
        element.arrivalCity +
        "-" +
        element.departionDate +
        "_";
      FlightsInfoArray = FlightsInfoArray + fligt;
    }
    return FlightsInfoArray.slice(0, -1);
  }
  passingerFormatter(array: any[]) {
    // convert array of passanger type number to A-1-C-0-I-0
    let passengersString: string;
    passengersString = "A-" + array[0] + "-C-" + array[1] + "-I-" + array[2];
    return passengersString;
  }
  cityData(lang: string) {
    //return cities data
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");

    let api = `${this.backOffice}/api/GetSearchFlowMapping?LangCode=${lang}`;
    let result: any;
    let errorOut: any;
    return this.httpClient.get<CitiesModule[]>(api, { headers: headers });
  }

  currencyApi(currency: string) {
    //return currency
    let api = `${this.admin}/api/CurrencyApi?currency=${currency}`;

    return this.httpClient.get<CurrencyModule[]>(api).pipe(
      take(1),
      map((result) => {
        console.log(result, "curruncy");
        return result;
      })
    );
  }
  pointOfSale(): Observable<any> {
    //return point of sale
    // let api = "https://ipapi.co/json";
    retry(2);
    let api = "https://api.ipify.org/?format=json";
    console.log("calll");
    return this.httpClient.get<ipA>(api).pipe(
      take(1),
      mergeMap((result) =>
        this.httpClient.get<PointOfsaleModule>(
          `https://ipapi.co/${result.ip}/json/`
        )
      )
      // this.pointofsale.emit(result);
    );
  }
  getHotelsCities() {
    //return all cities data;

    let api = `${this.Apihotels}/Api/SearchRequireData`;

    console.log(api);
    // return this.httpClient.get<hotelcitiesmodule[]>(api,{headers:headers})
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map((result) => {
        console.log(result.cities);
        return result.cities;
      })
    );
  }
  getHotels(searchHotel: SearchHoteltModule) {
    console.log(searchHotel,'search hotel data')
    let CityName = searchHotel.CityName;
    let DateFrom = searchHotel.checkIn;
    let DateTo = searchHotel.checkOut;
    let currency = searchHotel.Currency;
    let Nat = searchHotel.nation;
    let POS = searchHotel.POS;
    let Source = "Direct";
    let sID = searchHotel.serachId;
    let Lang = searchHotel.lan;
    let guestInfo = searchHotel.guestInfo;
    let SearchRooms: guest[] = [];

    // R0A3C2G4G3R1A2C0R2A1C1G3
    // str.split('-')[1];
    let arr = guestInfo.split("R");
    arr.splice(0, 1);
    //  getSearchRooms();
    let room: guest = { adult: 1, child: [0] };

    for (let i = 0; i < arr.length; i++) {

      let chNum: number = 0;
      let age: number[] = [];
      chNum = Number(arr[i].slice(4, 5));
      console.log("child", typeof chNum, chNum);
      if (chNum === 0) {
        age = [];
        console.log(chNum, "0child")
      }
      if (chNum === 1) {
        age = [];
        console.log(chNum, "1child")
        age.push(Number(arr[i].slice(6, 7)));
      }
      if (chNum === 2) {
        console.log(chNum, "2child")
        age = [];
        age.push(Number(arr[i].slice(6, 7)));
        age.push(Number(arr[i].slice(8, 9)));
        console.log(age, "age")
      }

      SearchRooms[i] = { adult: Number(arr[i].slice(2, 3)), child: age };


    }

    console.log("allRooms", SearchRooms);

    let getHotels: GetHotelModule ={
      CityName : CityName,
      DateFrom : DateFrom,
      DateTo : DateTo,
      Currency : currency,
      Nat : Nat,
      POS : POS,
      Source : Source,
      sID : sID,
      Lang : Lang,
      SearchRooms : SearchRooms
    }
      
    let api = `${this.Apihotels}/api/HotelSearch`;

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<any>(api, getHotels, {
        headers,
      })
      .pipe(take(1));
  } 
  GetHotelRooms(Pid, sid, hotelID) {
    // RETURN hOTELS ROOM
    let apiHotel = `${this.Apihotels}/`; // salama1472019446 /37094/4
    console.log(apiHotel, "myapi");
    return this.httpClient
      .get<HotelRoom>(
        apiHotel +
          "api/HotelRooms?sid=" +
          sid +
          "&hotel=" +
          hotelID +
          "&Pid=" +
          Pid
      )
      .pipe(take(1));
  }
  GetHotelRoomsNUM(sid, hotelID, Pid, rooms) {
    // RETURN hOTELS ROOM
    let apiHotel = `${this.Apihotels}/`; // salama1472019446 /37094/4
    return this.httpClient
      .get<RequiredHotel>(
        apiHotel +
          "api/HotelRooms?sid=" +
          sid +
          "&hotel=" +
          hotelID +
          "&Pid=" +
          Pid +
          "&rooms=" +
          rooms
      )
      .pipe(take(1));
  }
  HotelSaveBookingData(data) {
    // RETURN hOTELS ROOM
    //  let apiHotel=`http://192.168.1.102:3030/`;// salama1472019446 /37094/4
    let apiHotel = `${this.Apihotels}/`;

    return this.httpClient
      .post<any>(apiHotel + "api/HotelBooking", data)
      .pipe(take(1));
  }

  GetHPaymentView(
    ip: string,
    iplocation: string,
    HGNu: string,
    searchid: string,
    lang: string
  ) {
    let api = `${this.Apihotels}/api/PaymentView?bookingnum=${HGNu}&sid=${searchid}&ip=${ip}&Pos=${iplocation}&lang=${lang}&NotificationTok=""`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(take(1));
  }

  getHPaymentResult(url: string) {
    // get the payment result status for hotels
    let api = `${this.hotelPrepay}api/paymentresult?${url}`;
    return this.httpClient.get<any>(api).pipe(take(1));
  }
  PostProcessingH(HGNu: string, searchid: string, tok: string, url: string) {
    //  get satus after succesful payment
    let api = `${this.Apihotels}/Api/ConfirmHotelStatus?sid=${searchid}&bookingNum=${HGNu}&tok=${tok}`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(take(1));
  }
  getConfirmationH(HGNu: string, searchid: string) {
    // get return conformtion from the clint
    let api = `${this.Apihotels}/Api/Confirmation?sid=${searchid}&bookingNum=${HGNu}`;
    return this.httpClient.get<any>(api).pipe(take(3));
  }
  getConfirmation(HGNu: string, searchid: string,tok?:string) {
    // get return conformtion from the clint
    let api = `${this.BookingFlow}/api/BookingConfirmation?HG=${HGNu}&SId=${searchid}&tok=${tok}`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(
      take(3),
      map((result) => {
        return result;
      })
    );
  }

  metaProviderConfirmation(confirmationData:FlightSearchResult,hgNum:string) {
    // get return conformtion from the clint
    let api = `${this.BookingFlow}/api/MetaProviderConfirmation?
    metaClickId=${localStorage.getItem('click_id')}
    &confirmStatus=${'confirmed'}
    &bookingNumber=${hgNum}
    &pnr=${confirmationData.pnr}
    &commissionValue=${confirmationData.fareAmount *.02}
    &commissionCurrency=${'USD'}
    &paymentMethod=${''}`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(
      take(3),
      map((result) => {
        return result;
      })
    );
  }
  getPaymentResult(url: string) {
    // get the payment result status
    let api = `${this.hotelPrepay}/api/paymentresult?${url}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  PostProcessing(HGNu: string, searchid: string, tok: string, url: string) {
    //  get satus after succesful payment
    let api = `${url}&tok=${tok}`;
    console.log("this is tok", tok);
    console.log("this is api", api);
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map((result) => {
        return result;
      })
    );
  }
  
  gethotelAmenities(id: any) {
    // return hotel amenities by hotel is
    let api = `${this.Apihotels}/api/getAmenities?id=${id}`;
    return this.httpClient.get<any[]>(api).pipe(take(1));
  }
  roomcancelation(sid: string, hotelcode: any, roomindex: any, PId: any) {
    // return room cancelation roles
    let api = `${this.Apihotels}/api/getcancelpolicy?sid=${sid}&hotelcode=${hotelcode}&roomindex=${roomindex}&PId=${PId}`;
    return this.httpClient.get<any[]>(api).pipe(take(2));
  }
  fareRules(sid: string, seq: number, pKey: string) {
    //get fare rules for each flight
    let api = `${this.FareRules}/api/GetFareRules?SId=${sid}&SeqNum=${seq}&PKey=${pKey}`;
    console.log(api);

    return this.httpClient.get<FareRules[]>(api).pipe(take(1));
  }
  searchFlight(searchFlight: SearchFlightModule) {
    let language = searchFlight.lan;
    let currency = searchFlight.Currency;
    // let currency = 'KWD';
    // let SearchPoint = searchFlight.pointOfReservation;
    let SearchPoint = searchFlight.pointOfReservation;
    let flightType = searchFlight.flightType;
    let flightInfo = searchFlight.flightsInfo;
    let searchId = searchFlight.serachId;
    let passengers = searchFlight.passengers;
    let Cclass = searchFlight.Cclass;
    let directOnly = searchFlight.showDirect;
    // !!!dont add spaces between the constracted Api below
    let api: string = `${this.searchFlow}/flights/flightsSearch/${language}/${currency}/${SearchPoint}/${flightType}/${flightInfo}/${passengers}/${Cclass}/${directOnly}/all/0/0/Direct?searchID=${searchId}`;
    console.log(api);
    return this.httpClient.get<metaFlightResponce>(api).pipe(retry(2), take(1),map(v=>getmetaSearchResult(v,createSignatureString,addSignature,mapSignature,groupBysignature,overNight,addStopTime,addExperiance)) );;
  }

  getSelectedFlight(
    searchid: string,
    sequenceNum: number,
    providerKey: string
  ) {
    //retern selected flight
    debugger;
    let api = `${this.searchFlow}/api/GetSelectedFlight?searchid=${searchid}&SequenceNum=${sequenceNum}&PKey=${providerKey}`;
    console.log(api);
    return this.httpClient.get<Selectedflight>(api).pipe(take(1));
  }
  CheckFlightValidation(
    HGNu: string,
    lang: string,
    searchid: string,
    SeqNum: number,
    pkey: string
  ) {
    // check if the flight is still available
    let api = `${this.BookingFlow}/api/CheckFlightValidation?HGNum=${HGNu}&Language=${lang}&SearchId=${searchid}&SeqNum=${SeqNum}&PKey=${pkey}`;
    console.log("checkapi", api);
    return this.httpClient.get<any>(api).pipe(take(1));
  }

  offlineServices(SID: string,POS:string) {
    //return array of services
    let api = `${this.BookingFlow}/api/GetOfflineServices?SID=${SID}&POS=${POS}`;
    console.log(api,'ofline service req');
    return this.httpClient.get<OfflineServiceModule[]>(api).pipe(
      take(1),
      map((result) => {
        return result;
      })
    );
  }
  saveBooking(searchid: string, sequenceNum: number, body: PassengersModule, pkey: string) {
    //take passengers information and return HGNu number
    let api = `${this.BookingFlow}/api/SaveBooking?SearchId=${searchid}&SeqNum=${sequenceNum}&PKey=${pkey}`;
    console.log(api,'save booking');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(1),
      retry(3),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }




  GetPaymentView(
    ip: string,
    iplocation: string,
    HGNu: string,
    searchid: string,
    productId: any,
    selectedServvices: any[]
  ) {
    //return payment link//
    let api = `${this.BookingFlow}/api/GetPaymentView?IP=${ip}&IPLoc=${iplocation}&HG=${HGNu}&SId=${searchid}&NotifyToken=`;
    console.log(api, selectedServvices);
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json; charset=utf-8");
    let body = {
      UserSeletedInsurance: { ProductId: productId },
      UserSeletedServices: { SeletedServicesCodes: selectedServvices },
    };
    return this.httpClient
      .post<any>(api, body, {
        headers,
      })
      .pipe(
        take(1),
        map((result) => {
          console.log(result);
          return result;
        })
      );
  }

  findinsurance(HGNu: string, searchid: string) {
    // return selected insurance
    let api = `${this.BookingFlow}/api/Insurance?HG=${HGNu}&SearchId=${searchid}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map((result) => {
        return result;
      })
    );
  }

  UtilityAirports(lang:string = 'en') : Observable<AirPorts[]>{
    let API:string = `${this.backOffice}/api/GetSearchFlowMapping?LangCode=${lang}`;
    return this.httpClient.get<AirPorts[]>(API).pipe(retry(3),take(1),catchError(err=>{console.log(err);throw err}))
  }

  countrycode(lang: string) {
    //  take language and return contries and countries codes
    
    lang = lang.toUpperCase();
    let api = `${this.backOffice}/api/GetAllCountriesByLangName?LangCode=${lang}`;
    return this.httpClient.get<CountriescodeModule[]>(api).pipe(
      take(1),
    );
  }
  // users api 
  login(body: object) {
    //login api
    let api = `${this.users}/api/Account/Login`
    // let api = `http://46.166.160.65:7099/api/Account/Login`
    console.log(api);
    console.log(body, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  signup(body: object) {
    // sign up
    let api = `${this.users}/api/Account/Register`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(body, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  forgetPassword(email) {
    // forget password
    let api = `${this.users}/api/Account/ForgotPassword`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(email, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, email, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  UpdatePassword(email) {
    //change password
    let api = `${this.users}/api/Account/ChangePassword`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(email, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, email, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  UpcomingAndHistory(email: string) {
    //get upcoming and hestory data;
    // let api = `${this.BookingFlow}/api/HistoryAndUpcomingFlights?Email=${email}`;
    let api = `${this.BookingFlow}/api/HistoryAndUpcomingFlights?Email=${email}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  UpcomingAndHistoryH(email: string) {
    //get upcoming and hestory data;
    // let api = `${this.BookingFlow}/api/HistoryAndUpcomingFlights?Email=${email}`;
    let api = `${this.Apihotels}/Api/UpcomingAndHistory?mail=${email}`;
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  logoutapi() {
    // for user logout
    let api = `${this.users}/api/Account/Logout`;
    console.log(api);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )

  }

  userupdate(body: object) {
    //edit user account
    let api = `${this.users}/api/Account/Update?Isbase=1`
    // let api = `http://46.166.160.65:7099/api/Account/Register`
    console.log(api);
    console.log(body, 'this is body');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(1),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }

  // user family member Apis 
  getFamilymembers(userid: string) {
    // return all users family members registered data
    let api = `${this.admin}/api/FamilyMembersApi?customerId=${userid}`
    return this.httpClient.get<familymember[]>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  updateFamilymembers(body: object) {
    // updatefamily members registered data
    let api = `${this.admin}/api/FamilyMembersApi`
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.put<any>(api, body, {
      headers
    }).pipe(
      take(2),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  addFamilymember(body: object) {
    // add family member to existing user
    let api = `${this.admin}/api/FamilyMembersApi`
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<any>(api, body, {
      headers
    }).pipe(
      take(2),
      map(
        (result) => { console.log(result); return result }
      )
    )
  }
  deletFamilymember(userid: any, memberid: any) {
    // delete famely member from exitting user
    let api = `${this.admin}/api/FamilyMembersApi?id=${memberid}&custId=${userid}`
    return this.httpClient.delete<any>(api).pipe(
      take(1),
      map(
        (result) => { return result; }
      )
    )
  }
  getuserinfo() {
    // return all users family members registered data
    let api = `${this.users}/api/Account/UserInfo`;
    console.log(api);
    return this.httpClient.get<any>(api).pipe(
      take(1),
      map(
        (result) => { console.log("GET USER INFO",result); return result; }
      )
    )
  }

  openSnackBar(message: string, action: string,Staus:string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass:[Staus]
    });
  }

    // Offers api's

    GetAllOffers(pos: string):Observable<OfferDTO[]> {
      let API = `${environment.endPoints.offers.getAll}${pos}`;
      return this.httpClient.get<OfferDTO[]>(API).pipe(
       take(1),retry(3), catchError(err => { console.log(err, "ERROR IN GETTING ALL OFFERS"); throw err })
      )
    }
  
   
    GetOfferBYId(id: number | string):Observable<OfferDTO> {
      let API = `${environment.endPoints.offers.getByID}${id}`;
      return this.httpClient.get<OfferDTO>(API).pipe(
        retry(3), take(1), map(
          (res: any) => { return res }
        ), catchError(err => { console.log(err, "ERROR IN GETTING OFFER BY ID"); throw err })
      )
    }
 

    activateCobon(promo: string, Sid: string, sequenceNum: any, pkey: string) {
      //check the validity of cobon and return 
      // new price
      let api = `${this.BookingFlow}/api/GetPromotionDetails?PromoCode=${promo}&SearchId=${Sid}&SeqNum=${sequenceNum}&PKey=${pkey}  `;
      return this.httpClient.get<Cobon>(api).pipe(take(1));
    }


  GetTOPDist() :Observable<TopCityFlight[]>{
    // Return home page flight ofer content
    let api = `${this.FlightTop}/api/FlightsTopDistinations/GetAll`;
    return this.httpClient.get<TopCityFlight[]>(api).pipe(take(1));


  } 
    // get direct payment
    getDirectPayment(searchid: string, tok: string, BNu: string) {
      let api = `${this.admin}/api/DirectPayment?sid=${searchid}&token=${tok}&bookingNumber=${BNu}`;
      return this.httpClient.get<any>(api).pipe(
        take(3),
        map((result) => {
          console.log("apidirect", api);
          return result;
        })
      );
    }
  
  
}
