import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { Router } from '@angular/router';
// import { title } from './checkout/checkout.component';
import countriesen from 'src/assets/countries/countriesen.json';
import { MyapiService } from '../../../services/myapi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faEdit, faPlus, faSave, faUserTimes } from '@fortawesome/free-solid-svg-icons';
export interface familymember {
 ID :string,
 First_Name:string,
 Last_Name :string,
 Phone:string,
 Phone_Code:string,
 Nationality :string,
 Address:string,
 Date_Of_Birth :string,
 Passport_No:string,
 Passport_Expiration_Date:string,
 Relation_Type:string,
 Residence:string,
 Customer_ID:string,
 Gender :string,
 Salutations :string
}

@Component({
  selector: 'app-family-members',
  templateUrl: './family-members.component.html',
  styleUrls: ['./family-members.component.css']
})

export class FamilyMembersComponent implements OnInit {
  
  faPlus      = faPlus
  faEdit      = faEdit
  faUserTimes = faUserTimes
  faSave      = faSave

  users:FormGroup;
  phonenumber = 'phonenumber';
  @Input() userid:string ='';
  titels: any[] = [
    { value: 'Mr' },
    { value: 'Ms' },
    { value: 'Miss' },
    { value: 'Mrs' }
  ];
  countries:any[] = countriesen;
  edit:boolean[] =[];
  memberNumber = 0;
  user_id :string ='';
  
  constructor(private myapi: MyapiService, private _snackBar: MatSnackBar, private Router: Router) {
    this.users = new FormGroup({
      usersarray :new FormArray([])
    })
   }
 
  ngOnInit() {
   
    console.log(this.userid,"myid")
    this.myapi.getFamilymembers(this.userid).subscribe((result)=>{
      this.memberNumber =result.length;
      console.log("memeber_number",this.memberNumber)
      this.edit=[];
      result.map((res)=>{
        this.user_id = res.Customer_ID;
      (<FormArray>this.users.get('usersarray')).push(
        new FormGroup({
        ID :new FormControl(res.ID),
        First_Name:new FormControl(res.First_Name),
        Last_Name :new FormControl(res.Last_Name),
        Phone:new FormControl(res.Phone),
        Phone_Code:new FormControl(res.Phone_Code),
        Nationality :new FormControl(res.Nationality),
        Address:new FormControl(res.Address),
        Date_Of_Birth :new FormControl(res.Date_Of_Birth),
        Passport_No:new FormControl(res.Passport_No),
        Passport_Expiration_Date:new FormControl(res.Passport_Expiration_Date),
        Relation_Type:new FormControl(res.Relation_Type),
        Residence:new FormControl(res.Residence),
        Customer_ID:new FormControl(res.Customer_ID),
        Gender :new FormControl(res.Gender),
        Salutations :new FormControl(res.Salutations)
        })
      )
      this.edit.push(false);
      })
    })
  }
 editmemer(i){
   this.edit[i]=true;
 }
 save(i){
   debugger
  if (i >= this.memberNumber){
   this.postedit(i);
  }
  else {
     this.putedit(i);
  }
 }

 deletemember(user:FormGroup){
   let suser:familymember = user.value;
   console.log(user,"deleted user");
    this.myapi.deletFamilymember(suser.Customer_ID,suser.ID).subscribe(res=>{console.log(res),this.ngOnInit()});
 }
 addmember(i){
    this.edit.push(true);
    (<FormArray>this.users.get('usersarray')).push(
      new FormGroup({
      ID :new FormControl(i+1),
      First_Name:new FormControl('',),
      Last_Name :new FormControl(''),
      Phone:new FormControl(''),
      Phone_Code:new FormControl(''),
      Nationality :new FormControl(''),
      Address:new FormControl(''),
      Date_Of_Birth :new FormControl(''),
      Passport_No:new FormControl(''),
      Passport_Expiration_Date:new FormControl(''),
      Relation_Type:new FormControl(''),
      Residence:new FormControl(''),
      Customer_ID:new FormControl(this.userid),
      Gender :new FormControl(''),
      Salutations :new FormControl('')
      })
    )
 }
 postedit(i){
  let editThis:familymember = (<FormArray>this.users.get('usersarray'))["controls"][i].value;
  this.myapi.addFamilymember(editThis).subscribe(
    (rsult)=>{this.ngOnInit(),console.log(rsult,"request result",editThis)}
  )
 }
 putedit (i){
   debugger
  let editThis:familymember = (<FormArray>this.users.get('usersarray'))["controls"][i].value;
   this.myapi.updateFamilymembers(editThis).subscribe(
     
     (rsult)=>{this.ngOnInit(),console.log(rsult,"request result",editThis)}
   )
 }
 invalidPhone(i) {
  let phone: FormGroup = (<FormGroup>((<FormArray>this.users.get("usersarray"))["controls"][0]));
  if (phone.get('phonenum').invalid && (phone.get('phonenum').touched || phone.get('phonenum').dirty || phone.get('phonenum').hasError)) {
    this.phonenumber = "alertPhone";
  } else {
    this.phonenumber = "phonenumber";
  }
}
//  setphone to un valid
unvalidPhone(i) {
  let phone: FormGroup = (<FormGroup>((<FormArray>this.users.get("usersarray"))["controls"][0]));
  if (phone.get('phonenum').invalid) {
    this.phonenumber = "alertPhone";
  } else {
    this.phonenumber = "phonenumber";
  }
}
}
