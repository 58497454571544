<div class="body-users">
    <div class="checkOutpage">
        <app-header></app-header>
    </div>
    <div class="container user-container">

        <div class="row main">
            <div class="col-12 col-md-3 side-nave p-0">
                <div class="userinfo  card">
                    <h3 class="title"> {{'user.profile'|translate}}</h3>
                    <h4>
                        <div class="box">    <fa-icon class="" [icon]="faUser " aria-hidden="true" ></fa-icon> </div><span class="usrname"> {{username}}</span> </h4>
                    <h4>
                        <div class="box1">   <fa-icon class="" [icon]="faEnvelope " aria-hidden="true" ></fa-icon> </div><span class="usremail">{{email}}</span> </h4>
                    <h4>
                        <div class="box2">  <fa-icon class="" [icon]="faPhoneAlt " aria-hidden="true" ></fa-icon></div><span class="usrphonen">{{phone}}</span> </h4>
                </div>

                <mat-nav-list>
                    <mat-list-item (click)="select('edit')">
                        <fa-icon class="" [icon]="faUserEdit " aria-hidden="true" ></fa-icon>
             
                        <span style="margin-left: 9px;">{{'user.editAcount'|translate}}</span>
                        <fa-icon class="" [icon]="faChevronRight" aria-hidden="true" ></fa-icon>

                        
                    </mat-list-item>
                    <mat-list-item (click)="select('family')">
                        <fa-icon class="" [icon]="faUserFriends" aria-hidden="true" ></fa-icon>
                        <span style="margin-left: 9px;">{{'user.familyMembers'|translate}}</span>
                        <fa-icon class="" [icon]="faChevronRight" aria-hidden="true" ></fa-icon>
                    </mat-list-item>
                    <mat-list-item (click)="select('upcoming')">
                        <fa-icon class="" [icon]="faClipboardList" aria-hidden="true" ></fa-icon>

                        <span style="margin-left: 15px;">{{'user.myOrders'|translate}}</span>
                        <fa-icon class="" [icon]="faChevronRight" aria-hidden="true" ></fa-icon>
                    </mat-list-item>
                    <mat-list-item style=" margin-top: 20px;" (click)='logout()'>
                        <span>{{'user.signOut'|translate}}                   <fa-icon class="" [icon]="faSignOutAlt" aria-hidden="true" ></fa-icon>
                        </span>   
                    </mat-list-item>
                </mat-nav-list>
            </div>
            <div class="col-12 col-md-8 " style="margin-bottom: 20px;">
                <ng-container *ngIf="loading">
                    <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
                </ng-container>
                <div class="container main-content" *ngIf="!loading">
                    <app-orders *ngIf="selector == 'upcoming' && email !=''" [email]="email"></app-orders>
                    <!-- <app-upcoming-ahistory [email]="email" *ngIf="selector == 'upcoming'"></app-upcoming-ahistory> -->
                    <!-- <app-upcoming-ahistory-h [email]="email" *ngIf="selector == 'upcoming'"></app-upcoming-ahistory-h> -->
                    <app-family-members *ngIf="selector == 'family'" [userid]='id'></app-family-members>
                    <app-edit-acount *ngIf="selector == 'edit'" [name]='username' [email]='email' [Phone]='phone'></app-edit-acount>
                </div>

            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
<ng-template #loadingkst>
    <div class="preloader">

        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>

</ng-template>