import { RequiredHotel } from './../../modules/HotelBooking/RequiredHotelData';
import { state } from '@angular/animations';
import { SearchHoteltModule } from './../../modules/search-hotel/search-hotel.module';
import { loadHotelSearchsSuccess } from './hotel-search.actions';
import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../hotel-search/hotel-search.actions';
import { GetHotelModule } from './../../modules/search-hotel/get-hotels.module';
import { HotelRoom, RoomResult } from './../../modules/HotelRooms/HotelRooms';
import { HotelResult, HotelSearchResult } from './../../modules/search-hotel/search-hotel-result';
import { selectSelectedRooms } from '../selectors';


export const hotelSearchFeatureKey = 'hotel';

export interface State {
  loader :           boolean,
  searchResult:      HotelResult,
  hotelAmenties:     any,
  hotelRooms:        HotelRoom,
  requiredHotel:     RequiredHotel,
  selectedRooms:     RoomResult[],
  totalPrice:        number,
  paymentLink:       any,
  cancellationPolicy:any,
  filtredHotels:     HotelSearchResult[],
  searchCriteria:    SearchHoteltModule,
  roomImages:        string[]
  error:             any
}

export const initialState: State = {
  loader :           false,
  searchResult:      undefined,
  hotelAmenties:     undefined,
  hotelRooms:        undefined,
  requiredHotel:     undefined,
  selectedRooms:     [],
  totalPrice:        0,
  paymentLink:       undefined,
  cancellationPolicy:undefined,
  filtredHotels:      [],
  searchCriteria:    undefined,
  roomImages:        [],
  error :            undefined
};


export const reducer = createReducer(
  initialState,
  on(actions.loadHotelSearchs , (state) => ({...state , loader : true})),
  on(actions.loadHotelSearchsSuccess , (state , param) =>({...state , loader:false , searchResult:param.data,filtredHotels:param.data.HotelResult})),
  on(actions.loadHotelSearchsFailure , (state , param) => ({...state , loader : false , error : param.error})),
  on(actions.updateHotelSearchCretaria ,(state , param) =>({...state , loader:false , searchCriteria:param.data})),
  on(actions.updateFiltiredHotels , (state , param) =>({...state , loader:false , filtredHotels:param.data})),
  on(actions.loadHotelRooms , (state) => ({...state , loader : true})),
  on(actions.loadHotelRoomsSuccess , (state , param) =>({...state , loader:false , hotelRooms:param.data})),
  on(actions.loadHotelRoomsFailure , (state , param) => ({...state , loader : false , error : param.error})),
  on(actions.updateSelectedRooms , (state , param) =>({...state , loader:false , selectedRooms:check([...state.selectedRooms],param.data) ,totalPrice:state.totalPrice + param.data.TotalSellPrice})),
  on(actions.loadHotelAmenties , (state) => ({...state , loader : true})),
  on(actions.loadHotelAmentiesSuccess , (state , param) =>({...state , loader:false , hotelAmenties:param.data})),
  on(actions.loadHotelAmentiesFailure , (state , param) => ({...state , loader : false , error : param.error})),
  on(actions.hotelSaveBooking , (state) => ({...state, loader:true})),
  on(actions.hotelSaveBookingSuccess , (state,param)=>({...state , loader:false , paymentLink:param.data})),
  on(actions.hotelSaveBookingFailure , (state , param) =>({...state , loader:false , error:param.error})),
  on(actions.loadRoomCancellation , (state) => ({...state , loader : true})),
  on(actions.loadRoomCancellationSuccess , (state , param) =>({...state , loader:false , cancellationPolicy:param.data})),
  on(actions.loadRoomCancellationFailure , (state , param) => ({...state , loader : false , error : param.error})),
  on(actions.loadSelectedHotel , (state) => ({...state , loader:true})),
  on(actions.loadSelectedHotelSuccess , (state , param) => ({...state , loader : false , requiredHotel:param.data})),
  on(actions.loadSelectedHotelFailure , (state , param) => ({...state , loader : false , error : param.error})),
  on(actions.updateRoomImages , (state , param) => ({...state , roomImages:param.data})),
);

export function HotelReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}
type check = (selectedromes:RoomResult[],actionprop:RoomResult)=>RoomResult[];

export const check:check = function(selectedromes:RoomResult[],actionProp:RoomResult):RoomResult[]{
  let index = selectedromes.findIndex((v)=>v.roomIndex === actionProp.roomIndex)
  let updated = [...selectedromes];
  if(index != -1){
    updated[index] = actionProp
   return updated
  }
  updated.push(actionProp)
  console.log(updated,'NOT FOUNDED')
  return updated

}
