
import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State} from './store/selectors'
import { loadUtilitysCountries, loadUtilitysPOS } from './store/app-utilities/app-utilities.actions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private translate: TranslateService,readonly store:Store<State>) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
  
     // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  
  }
  ngOnInit(){
    this.store.dispatch(loadUtilitysPOS());

    this.store.dispatch(loadUtilitysCountries({lang:this.translate.currentLang}));
  }
}
