import { Action, createReducer, on } from '@ngrx/store';
import { airItineraries, FareRules, FlightSearchResult, itinTotalFare, OfflineServiceModule, Selectedflight } from '../../interfaces/flight-search-result';
import { metaFlightResponce, metaSearchResult, signatureMapedItinerary } from '../../interfaces/meta-flight';
import { SearchFlightModule } from '../../modules/search-flight/search-flight.module';
import * as flights from '../flight-search/flight-search.actions';


export const flightSearchFeatureKey = 'flight';

export interface State {
  loader         : boolean,
  flights        : FlightSearchResult|metaSearchResult|metaFlightResponce,
  flightsLowestFare: itinTotalFare,
  selectedFlight : Selectedflight,
  searchCretaria : SearchFlightModule,
  filterdFlights :signatureMapedItinerary[][]
  offlineServices:OfflineServiceModule[],
  fareRules:FareRules[],
  link:string,
  error:any
}

export const initialState: State = {
  loader         : false,
  flights        : undefined,
  selectedFlight : undefined,
  searchCretaria : undefined,
  filterdFlights: undefined,
  error          :undefined,
  offlineServices:undefined,
  fareRules      :undefined,
  link:undefined,
  flightsLowestFare:undefined
};


export const reducer = createReducer(
  initialState,
  on(flights.loadFlightSearchs , (state)=> ({...state , loader:true})),
  on(flights.loadFlightSearchsSuccess , (state , Flights)=>({...state , loader : false ,flights:Flights.data})),
  on(flights.loadFlightSearchsFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.loadFares , (state)=> ({...state , loader:true})),
  on(flights.loadFaresSuccess , (state ,fares)=>({...state , loader : false , fareRules:fares.data})),
  on(flights.loadFaresFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.loadOfflineSrvices , (state)=> ({...state , loader:true})),
  on(flights.loadOfflineSrvicesSuccess, (state ,services)=>({...state , loader : false , offlineServices:services.data})),
  on(flights.loadOfflineSrvicesFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.loadSelectedFlight , (state)=> ({...state , loader:true})),
  on(flights.loadSelectedFlightSuccess , (state , flight)=>({...state , loader : false , selectedFlight:flight.data })),
  on(flights.loadSelectedFlightFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.updateSearchCretaria , (state , searchData)=> ({...state , searchCretaria:searchData.searchData})),
  on(flights.SaveBooking , (state)=> ({...state , loader:true})),
  on(flights.SaveBookingFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.SaveBookingSuccess , (state , link)=>({...state , loader : false , link:link.data})),
  on(flights.filterFlight , (state)=> ({...state })),
  on(flights.filterFlightSuccess , (state , Flights)=>({...state , loader : false ,filterdFlights:Flights.airItineraries})),
  on(flights.filterFlightFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.sortFlightSuccess , (state , param) => ({...state , filterdFlights:param.airItineraries})),
  on(flights.sortFlightFailure , (state , param) => ({...state , error:param.err})),
  on(flights.loadFlightSearchsLowestFare , (state) => ({...state , loader : true})),
  on(flights.loadFlightSearchsLowestFareSuccess , (state , param) => ({...state , loader : false , flightsLowestFare: param.data})),
  on(flights.loadFlightSearchsLowestFareFailure , (state , param) => ({...state , loader : false , error:param.err})),
  on(flights.resetFlightResults , (state) => ({...initialState})),

);

export function FlightReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}

