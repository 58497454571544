<div class="container-fluid">
    <div class="row">
        <div class="imgBack">
            <div class="card col-12 col-12 col-md-4" style="margin-top: 15vh; padding: 20px">
                <div class="col-12 text-center">
                    <a routerLink="/">
                        <img src="../../assets/img/torest/Group.png" class="logo" alt="logo" />
                    </a>
                </div>
                <div class="col-12 text-center">
                    <h4 class="signUpToText">{{translate.currentLang=='en'?'Sign Up To khaleejgate' : 'انشاء حساب في khaleejgate'}}</h4>
                </div>
                <div style="min-height: fit-content card-body">
                    <form [formGroup]="signupform" class="row justify-content-center" (ngSubmit)="onSubmit()">
                        <div class="col-12 col-md-10">
                            <mat-form-field color="accent" appearance="outline" class="authForm">
                                <i class="fas fa-user feildicon" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang=='en'?'Username':'اسم المستخدم'"
                                 required formControlName="User_Name" type="email" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-5">
                            <mat-form-field color="accent" appearance="outline" class="authForm">
                                <i class="fas fa-user-tag feildicon" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang == 'en'?'First Name' : 'الاسم الاول'"
                                 required formControlName="FirstName" type="email" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-5">
                            <mat-form-field color="accent" appearance="outline" class="authForm">
                                <!-- <i class="fas fa-user feildicon" matPrefix></i> -->
                                <input matInput [placeholder]="translate.currentLang=='en'?'Last Name':'الاسم الاخير'"
                                 required formControlName="LastName" type="email" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-10">
                            <mat-form-field color="accent" appearance="outline" class="authForm">
                                <i class="fas fa-envelope feildicon" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang == 'en'?'Email Address':'البريد الالكتروني'" 
                                required formControlName="Email" type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-10">
                            <mat-form-field color="accent" appearance="outline" class="authForm">
                                <i class="fas fa-phone feildicon" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang == 'en'?'Phone Number':'رقم الهاتف'"
                                 required formControlName="PhoneNumber" type="" />
                            </mat-form-field>
                        </div>
        
                        <div class="col-12 col-md-10">
                            <mat-form-field color="accent" appearance="outline" class="authForm">
                                <i class="fas fa-lock feildicon" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang=='en'?'Password':'كلمة السر'"
                                 required formControlName="Password" type="password" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-10">
                            <mat-form-field color="accent" appearance="outline" class="authForm">
                                <i class="fas fa-lock feildicon" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang == 'en'?'Retype PassWord':'اعد كتابة كلمة السر'" 
                                required formControlName="ConfirmPassword" type="password" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-10 text-center">
                            <button class="SingInButton" color="accent" mat-raised-button click="onSubmit">
                    {{translate.currentLang=='en'?'Sign UP':'انشاء الحساب'}}
                  </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
</div>
