<div class="container-fluid">
    <div class="row">
        
        <div class="imgBack">
            <div class=" col-12 col-12 col-md-4 card" style="margin-top: 5vh ;padding: 20px;">
                <div class="col-12 text-center">
                    <a routerLink="/">
                        <img src="../../assets/img/torest/Group.png" class="logo" alt="logo"> 
                    </a>
        
                </div>
                <div class="col-12 text-center">
                    <h4 class="login-title">{{translate.currentLang=='en'?'Login to your account':'قم بتسجيل الدخول'}}</h4>
                </div>
                <div style="min-height: fit-content ">
                    <form [formGroup]="loginform" class="row justify-content-center " (ngSubmit)="onSubmit()">
                        <div class="col-12 col-md-7">
                            <mat-form-field color="accent" appearance="fill" class="authForm">
                                <i class="fas fa-envelope feildicon-login" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang == 'en'?'Email Address':'البريد الالكتروني'" 
                                 required formControlName='Email' type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-7">
                            <mat-form-field color="accent" appearance="fill" class="authForm">
                                <i class="fas fa-lock feildicon-login" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang=='en'?'Password':'كلمة السر'"
                                 required formControlName='Password' type="password">
                            </mat-form-field>
        
                        </div>
                        <div class=" col-12 col-md-7 text-right">
                            <!-- <a href=""> Forget PassWord ?</a> -->
                        </div>
                        <div class=" col-12 col-md-7 text-center">
                            <button class="SingInButton" mat-raised-button color="accent" click='onSubmit'>
                                {{translate.currentLang=='en'?'Sign In':'انشاء حساب'}}
                            </button>
                        </div>
                        <div class="col-12 col-md-7 text-center">
                            <a [routerLink]="['/signup']"> {{translate.currentLang=='en'?'Sign Up':'انشاء حساب'}}</a>
                        </div>
        
                    </form>
        
                </div>
                <ng-container *ngIf="preloader">
                    <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
                </ng-container>
            </div>
        </div>
        
    </div>
</div>


<ng-template #loadingkst>
    <div class="preloader">

        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>

</ng-template>