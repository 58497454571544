import { environment } from 'src/environments/environment';
import { MyapiService } from 'src/app/services/myapi.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as appUtilities from '../app-utilities/app-utilities.actions';
import * as appSetting from '../app-setting/app-setting.actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TopCityFlight, tripideas } from 'src/app/interfaces/Home-content';
import { Store } from '@ngrx/store';

@Injectable()
export class AppUtilitiesEffects {
  constructor(
    private actions$   : Actions,
    private readonly store : Store,
    private apiService : MyapiService) {}

  loadAirports$ = createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysAirports),mergeMap(
    (lang) =>  this.apiService.UtilityAirports(lang.lang).pipe(
      map(v=>{
        return appUtilities.loadUtilitysAirportsSuccess({airports:v})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysAirportsFailure({error:err}))
      })
    )
  ))
  )

  loadCurrency$ = createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysCurrency),mergeMap(
    () =>  this.apiService.currencyApi(environment.baseCurrency).pipe(
      map(v=>{
         
        return appUtilities.loadUtilitysCurrencySuccess({Currency:v})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysCurrencyFailure({error:err}))
      })
    )
  ))
  )

  loadPOS$ = createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysPOS),mergeMap(
    () =>  this.apiService.pointOfSale().pipe(
     
      switchMap(v=>[
        appSetting.updateAppPOS({POS:v}),
        appUtilities.loadUtilitysPOSSuccess({POS:v})
      ]),
      catchError(err => {
        return of(appUtilities.loadUtilitysPOSFailure({error:err}))
      })
    )
  ))
  )

  loadCountries$ =createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysCountries),mergeMap(
    (lang) =>  this.apiService.countrycode(lang.lang).pipe(
      map(v=>{
        return appUtilities.loadUtilitysCountriesSuccess({countries:v})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysCountriesFailure({error:err}))
      })
    )
  ))
  )
 
  loadCites$ =createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysCities),mergeMap(
    () =>  this.apiService.getHotelsCities().pipe(
      map(v=>{
        return appUtilities.loadUtilitysCitiesSuccess({cities:v.ci})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysCitiesFailure({error:err}))
      })
    )
  ))
  )

  loadTopCities$ =createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadFlightTopCities),mergeMap(
    () =>  this.apiService.GetTOPDist().pipe(
      map(v=>{
        let TopDest = v.filter(c=> !c.tripIdeas);
        let tripIdeas = v.filter(c=>c.tripIdeas);
        if(tripIdeas){
          this.store.dispatch(appUtilities.loadFlightTripIdeas({Tcities:tripIdeas}));
        }
        if(TopDest){
          console.log(TopDest,GroupbyFour(TopDest),'sdfgsdf');
        return appUtilities.loadFlightTopCitiesSuccess({Tcities:GroupbyFour(TopDest)});
        }else{
          return appUtilities.loadFlightTopCitiesFailure({error:'No top Disr available'});
        }
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysCitiesFailure({error:err}));
      })
    )
  ))
  )

  loadTripIdeas$ =createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadFlightTripIdeas),
  map((v)=>{
    console.log(GroupByTags(v.Tcities),'ideas');
    return appUtilities.loadFlightTripIdeasSuccess({ideas:GroupByTags(v.Tcities)})
  }),
  catchError(err => {
    return of(appUtilities.loadFlightTripIdeasFailure({error:err}))
  })
  )
  )
}


type GroupyFour = (FTC:TopCityFlight[])=> TopCityFlight[][];
export const  GroupbyFour:GroupyFour = function(v:TopCityFlight[]):TopCityFlight[][]
{
  let gruped:TopCityFlight[][] = [];
  let pointer:number =0
 for (let index = 0; index < v.length; index++) {
   if((index + 1) % 4  === 0 && (index + 1) != v.length){
     console.log(index,'ii');
     
     let sub = v.slice(pointer,index + 1);
     pointer = index +1
     
     gruped.push(sub)

   }else if((index + 1) === v.length) {
    let sub = v.slice(pointer);
    console.log(index,'iii');
    gruped.push(sub)
   }
   
 }
  return gruped;
}
type GroupByTags = (FTC:TopCityFlight[])=>tripideas[];
export const GroupByTags: GroupByTags = function (array: TopCityFlight[]): tripideas[] {
  console.log(array.length,'ideas')
  let join :TopCityFlight[][]=[];
  let remain:TopCityFlight[] =array;
  let out:tripideas[] = []
  let i = 0;
  while (remain.length >0 || !remain) {
    join.push(remain.filter((v,i,a)=>v.tag ===a[0].tag ));
      remain = remain.filter((v,i,a)=>v.tag != a[0].tag);
    i = i +1
  }
  console.log(out,'RETURN THE Trip ideas');
  out = join.map((v)=>{
    let c:tripideas = {tripIdea:v[0].tag,
      tribIdeaAr:v[0].tagAr,
      trips:v};
    return c
   })
 return out

}