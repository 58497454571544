import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './components/articles/about-us/about-us.component';
import { ContactComponent } from './components/articles/contact/contact.component';
import { PrivacyComponent } from './components/articles/privacy/privacy.component';
import { TermOfuseComponent } from './components/articles/term-ofuse/term-ofuse.component';
import { ConfirmationComponent } from './components/flight-cofirmation/confirmation/confirmation.component';
import { UserLoginComponent } from './components/users/user-login/user-login.component';
import { UserSignupComponent } from './components/users/user-signup/user-signup.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [  {
  path:'',
  loadChildren:()  =>import('./components/home/home.models').then((m)=> m.HomeModule) 
},
{
  path:'flightResult',
  // path:"('/flightResult', {skipLocationChange: true}).then(()=>{this.router.navigate([newurl])",
  // path:"(['/flightResult'],{queryParams:{'language':language,'currency':currency,'SearchPoint':SearchPoint,'flightType':flightType,'flightInfo':flightInfo,'searchId':searchId,'passengers':passengers,'Cclass':Cclass,'directOnly':directOnly}})",
  loadChildren:()  =>import('./components/flight/flight.models').then((m)=> m.FlightModule) 
},
{ path: 'aboutUs', component: AboutUsComponent },
{ path: 'Contact', component: ContactComponent },
{ path: 'Privacy', component: PrivacyComponent },
{ path: 'TermOfUse', component: TermOfuseComponent },
{
  path:'checkout',
  loadChildren:()  =>import('./components/flight-checkout/checkout.module').then((m)=> m.CheckoutModule) 
},


{ path: 'paymentresult', loadChildren: () => import('./components/flight-cofirmation/comfirmation.module').then(m => m.PreComfirmationModule) },
{
  path:'login' , component:UserLoginComponent
},
{
  path:'profile' , component:UsersComponent
},

{
  path:'signup' , component:UserSignupComponent
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
