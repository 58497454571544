<div [ngClass]="{navbarContainer:inHome , navbarContainerNotHome:!inHome}" class="web-header">
  <div class="container-fluid ">
    <div class="row">

      <div class="col-12 col-lg-2">
        <img (click)="goToHome()" width="100%"  class="logo-head" src="assets/img/torest/Group.png">
      </div>

      <div class="col-6 col-lg-3" style="text-align: start;">
        <div class="links" *ngIf="!isRestricted">
          <a [routerLink]="['/home']" class="nav-link active">
            <img src="../../../assets/icons/flight.png" alt="flight">
           <div>
            {{ "header.flight" | translate }}
           </div>
           
            
          </a>
          <!-- <a class="nav-link">
            <img src="../../../assets/icons/hotels.png" alt="hotels">
            <div>
              {{ "header.hotels" | translate }}
            </div>
           </a> -->
          <!-- <a class="nav-link">Train</a> -->
        </div>
      </div>

      <div class="col-12 col-lg-7" style="text-align: end;">
        <div class="actions " [ngClass]="{'mt-1':isRestricted,'mt-4':!isRestricted}">

          <!-- <button class="actionBtn" mat-button [matMenuTriggerFor]="pos">
            {{translate.currentLang == 'en'? selectedPOSEn : selectedPOSAr}} <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu #pos="matMenu">
            <button (click)="changePOS(pos)" *ngFor="let pos of allPOS" mat-menu-item>
              {{translate.currentLang == 'en'? pos.country_name : pos.country_nameAr}}
            </button>
          </mat-menu> -->
          
          <!-- <button *ngIf="!logedin" class="actionBtn" mat-button 
          (click)="goToSignUp()">
            {{ "user.signUp" | translate }}
          </button> -->

          <button *ngIf="!logedin " class="actionBtn action2" mat-button
          (click)="goToLogin()">
            {{ "user.login" | translate }} / {{ "user.signUp" | translate }}
          </button>

          <button *ngIf="logedin" class="actionBtn " mat-button 
          (click)="goToProfile()">
          {{ "user.profile" | translate }}
          </button>
          <button class="actionBtn b-0 pad-0" mat-button [matMenuTriggerFor]="LangSwitch"  >
            {{lang | uppercase}} 
          </button>
          <mat-menu #LangSwitch="matMenu">
            <button mat-menu-item (click)="langSwitch('en')">EN</button>
            <button mat-menu-item (click)="langSwitch('ar')">العربية</button>
          </mat-menu>
          <ng-container *ngIf="selectedCurrency$ |async as curruncey" >
          <button 
           class="actionBtn b-0 ms-0 ps-0" mat-button [matMenuTriggerFor]="currSwitcher" *ngIf="!isRestricted">
           <img width="20" height="15" src="{{curruncey['Image_Url']}}"> {{ curruncey["Currency_Code"] }} <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </ng-container>
          <mat-menu #currSwitcher="matMenu">
            <button mat-menu-item *ngFor="let curruncy of Currencies$ |async" (click)="changeCurrency(curruncy)">
             <img width="25" height="25" [src]="curruncy.Image_Url">  {{ curruncy["Currency_Code"] }}
            </button>
          </mat-menu>

        </div>
      </div>

    </div>
    <div class="Border-Raber" *ngIf="!isRestricted">
      <section class="Border"></section>
    </div>
    
  </div>
  
</div>
<div  [ngClass]="{navbarContainer:inHome , navbarContainerNotHome:!inHome}" class="mobile-header">
  <div class="container-fluid p-0">
    <div class="row">

      <div class="col-5">
        <img (click)="goToHome()" width="100%"  class="logo-head2" src="assets/img/torest/Group.png">
      </div>
      <div class="col-7">
        <fa-icon class="iconMenu" [icon]="faBars" aria-hidden="true"  (click)="toggleDetails()"
        ></fa-icon>
        <div>
          <ul class="item-list" *ngIf="showDetails">
               <li>
                <div class=" action3" *ngIf="!isRestricted">
                  <a [routerLink]="['/home']" class="nav-link active">
                 
                    
                   <div>
                    {{ "header.flight" | translate }}
                   </div>
                   
                    
                  </a>
                  <!-- <a class="nav-link action3">
                    
                    <div>
                      {{ "header.hotels" | translate }}
                    </div>
                   </a> -->
                  <!-- <a class="nav-link">Train</a> -->
                </div>
               </li>
               <!-- <button *ngIf="!logedin " class="actionBtn action2" mat-button
               (click)="goToLogin()">
                 {{ "user.login" | translate }} / {{ "user.signUp" | translate }}
               </button>
     
               <button *ngIf="logedin" class="actionBtn " mat-button 
               (click)="goToProfile()">
               {{ "user.profile" | translate }}
               </button>
               <button class="actionBtn b-0 pad-0" mat-button [matMenuTriggerFor]="LangSwitch"  *ngIf="!isRestricted">
                 {{lang | uppercase}} 
               </button> -->

               <li>
                 <a *ngIf="!logedin " class="actionBtn2 action2 action3" 
                 
                 (click)="goToLogin()">
                   {{ "user.login" | translate }} / {{ "user.signUp" | translate }}</a>
               </li>
               <li>
                 <a href="" *ngIf="logedin" class="actionBtn action3 "
                 
                 (click)="goToProfile()">
                 {{ "user.profile" | translate }}</a>
               </li>
               <li>
                <button class="actionBtn action3 b-0 pad-0" mat-button [matMenuTriggerFor]="LangSwitch"  >
                  {{lang | uppercase}} 
                </button>
                <mat-menu #LangSwitch="matMenu">
                  <button mat-menu-item (click)="langSwitch('en')">EN</button>
                  <button mat-menu-item (click)="langSwitch('ar')">العربية</button>
                </mat-menu>
               </li>
               <li>
                <ng-container *ngIf="selectedCurrency$ |async as curruncey" >
                  <button 
                   class="actionBtn action3 b-0 ms-0 ps-0" mat-button [matMenuTriggerFor]="currSwitcher" *ngIf="!isRestricted">
                   <img width="20" height="15" src="{{curruncey['Image_Url']}}"> {{ curruncey["Currency_Code"] }} <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                </ng-container>
                  <mat-menu #currSwitcher="matMenu">
                    <button mat-menu-item *ngFor="let curruncy of Currencies$ |async" (click)="changeCurrency(curruncy)">
                     <img width="25" height="25" [src]="curruncy.Image_Url">  {{ curruncy["Currency_Code"] }}
                    </button>
                  </mat-menu>
               </li>
          </ul>
        </div>
         
      </div>
    </div>

  </div>
</div>